import React, { useEffect, useState } from 'react';
import { Container, Typography, Tabs, Tab, Box, Alert, MenuItem, Select, FormControl, InputLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { getValidAccessToken, getUserType } from '../utils/tokenUtils';
import MessagesTab from './TicketTabs/MessagesTab';
import TaxOrganizerTab from './TicketTabs/TaxOrganizerTab';
import MyDocumentsTab from './TicketTabs/MyDocumentsTab';
import TaxDraftTab from './TicketTabs/TaxDraftTab';
import InvoiceTab from './TicketTabs/InvoiceTab';
import FinalDocumentsTab from './TicketTabs/FinalDocumentsTab';

const apiUrl = process.env.REACT_APP_API_URL;


const STATUS_TRANSITIONS = {
    'Upfront Review': ['Under Tax Preparation'],
    'Under Tax Preparation': ['Need More Information', 'Draft Sent'],
    'Need More Information': ['Under Tax Preparation'],
    'Draft Sent': ['Draft Approved', 'Payment Made'],
    'Draft Rejected': [],
    'Payment Made': ['E-Filing', 'ITIN', 'Paper Filing'],
    'E-Filing': ['8879-Sent'],
    '8879-Sent': ['8879-Received'],
    '8879-Received': ['E-Filing Completed'],
    'E-Filing Completed': ['Final Copies Sent'],
    'Final Copies Sent': ['Closed'],
    'ITIN': ['ITIN Applied'],
    'ITIN Applied': ['ITIN Received'],
    'ITIN Received': ['State Return Filed'],
    'State Return Filed': ['Final Copies Sent'],
    'Paper Filing': ['Final Copies Sent']
};


const TicketData = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const ticketId = query.get('ticket_id');

    const [ticketData, setTicketData] = useState(null);
    const [customerData, setCustomerData] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [value, setValue] = useState(0);
    const [userType, setUserType] = useState('');
    const [newStatus, setNewStatus] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [agents, setAgents] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState('');
    const [openAgentDialog, setOpenAgentDialog] = useState(false);


    useEffect(() => {
        const fetchTicketData = async () => {
            const token = await getValidAccessToken();
            try {
                const response = await axios.get(`${apiUrl}/user-ticket/?ticket_id=${ticketId}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                if (response.data.success) {
                    setTicketData(response.data.ticket_data[0]);

                } else {
                    setErrorMessage('Unable to fetch ticket data.');
                }
            } catch (error) {
                setErrorMessage('Error fetching ticket data.');
                console.error('Error fetching ticket data:', error);
            }
        };




        const fetchAgents = async () => {
            const token = await getValidAccessToken();
            try {
                const response = await axios.get(`${apiUrl}/active-agents/`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                if (response.data.success) {
                    setAgents(response.data.users);
                } else {
                    setErrorMessage('Unable to fetch agent list.');
                }
            } catch (error) {
                setErrorMessage('Error fetching agent list.');
                console.error('Error fetching agent list:', error);
            }
        };

        const fetchUserType = async () => {
            const type = await getUserType();
            setUserType(type);
            if (type === 'agent') {
                await fetchAgents();
            }
        };

        const fetchUserDetails = async () => {
            const token = await getValidAccessToken();
            try {
                if (ticketId != null) {


                    const response = await axios.get(`${apiUrl}/user-details?ticket_id=${ticketId}`, {
                        headers: {'Authorization': `Bearer ${token}`}
                    });

                    if (response.data.success) {
                        //console.log(JSON.parse(JSON.stringify(response.data.user_data)))
                        setCustomerData(response.data.user_data)
                    } else {
                        setErrorMessage('Unable to fetch User data.');
                    }
                }
            } catch (error) {
                setErrorMessage('Error fetching User data.');
                console.error('Error fetching User data:', error);
            }
        };

        fetchTicketData();
        fetchUserType();
        fetchUserDetails();
    }, [ticketId]);



    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
        setOpenDialog(true);
    };

    const confirmStatusUpdate = async () => {
        const token = await getValidAccessToken();
        try {
            const response = await axios.get(`${apiUrl}/update-ticket-status/?ticket_id=${ticketId}&ticket_status=${selectedStatus}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            if (response.data.success) {
                setTicketData((prevData) => ({ ...prevData, ticket_status: selectedStatus }));
                setErrorMessage('');
            } else {
                setErrorMessage('Failed to update ticket status.');
            }
        } catch (error) {
            setErrorMessage('Error updating ticket status.');
            console.error('Error updating ticket status:', error);
        } finally {
            setOpenDialog(false);
        }
    };

    const handleAgentChange = (event) => {
        setSelectedAgent(event.target.value);
        setOpenAgentDialog(true);
    };

    const confirmAgentUpdate = async () => {
        const token = await getValidAccessToken();
        try {
            const response = await axios.post(
                `${apiUrl}/update-ticket-assignee/`,
                {
                    ticket_id: ticketId,
                    new_assignee_name: selectedAgent
                },
                {
                    headers: { 'Authorization': `Bearer ${token}` }
                }
            );

            if (response.data.success) {
                setTicketData((prevData) => ({ ...prevData, assignee_agent_name: selectedAgent }));
                setErrorMessage('');
            } else {
                setErrorMessage('Failed to update ticket assignee.');
            }
        } catch (error) {
            setErrorMessage('Error updating ticket assignee.');
            console.error('Error updating ticket assignee:', error);
        } finally {
            setOpenAgentDialog(false);
        }
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setOpenAgentDialog(false);
    };

    return (
        <Box style={{ width: '100%', padding: '20px' }}>
            <Typography variant="h5" gutterBottom>
                Ticket Details
            </Typography>

            {errorMessage && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {errorMessage}
                </Alert>
            )}

            {ticketData && (
                <Box display="flex" flexDirection="row" gap={1} alignItems="center">
                    <Typography variant="body1" sx={{ fontSize: '12px' }}>
                        Ticket ID: #{ticketData.ticket_id}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: '12px' }}>
                       Customer Name: #{customerData != null ? customerData.first_name + ''+customerData.last_name : ''}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: '13px' }}>
                        Year: {ticketData.service_year_value}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: '13px' }}>
                        Service: {ticketData.service_name || 'N/A'}
                    </Typography>
                    <Typography variant="body1" sx={{
                        backgroundColor: '#ff4700',
                        color: 'white',
                        borderRadius: '12px',
                        px: 2,
                        py: 1,
                        fontSize: '12px',
                        fontWeight: 'bold'
                    }}>
                        Status: {ticketData.ticket_status || 'N/A'}
                    </Typography>

                    {userType === 'agent' && (
                        <>
                            <Box sx={{ ml: 2, minWidth: 150 }}>
                                <FormControl fullWidth>
                                    <InputLabel
                                        shrink
                                        sx={{
                                            fontSize: '15px',
                                            top: -8,
                                            fontWeight: 'bold',
                                            color: 'black',
                                            left: -10
                                        }}
                                    >
                                        Change Status
                                    </InputLabel>
                                    <Select
                                        value={newStatus || ticketData.ticket_status}
                                        onChange={handleStatusChange}
                                        displayEmpty
                                        sx={{
                                            fontSize: '13px',
                                            height: '2.0rem',
                                            '.MuiSelect-select': {
                                                padding: '10px',
                                            },
                                        }}
                                    >
                                        {ticketData.ticket_status && STATUS_TRANSITIONS[ticketData.ticket_status] && STATUS_TRANSITIONS[ticketData.ticket_status].map((status, index) => (
                                            <MenuItem
                                                key={index}
                                                value={status}
                                                sx={{ fontSize: '13px' }}
                                            >
                                                {status}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>

                            <Box sx={{ ml: 2, minWidth: 150 }}>
                                <FormControl fullWidth>
                                    <InputLabel
                                        shrink
                                        sx={{
                                            fontSize: '15px',
                                            top: -8,
                                            fontWeight: 'bold',
                                            color: 'black',
                                            left: -10
                                        }}
                                    >
                                        Assignee
                                    </InputLabel>
                                    <Select
                                        value={selectedAgent || ticketData.assignee_agent_name}
                                        onChange={handleAgentChange}
                                        displayEmpty
                                        sx={{
                                            fontSize: '13px',
                                            height: '2.0rem',
                                            '.MuiSelect-select': {
                                                padding: '10px',
                                            },
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        {agents.length > 0 ? (
                                            agents.map((agent, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={agent}
                                                    sx={{ fontSize: '13px' }}
                                                >
                                                    {agent}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem disabled>No agents available</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Box>

                            <Box sx={{ ml: 2, minWidth: 150 }}>
                                <FormControl fullWidth>
                                    <InputLabel
                                        shrink
                                        sx={{
                                            fontSize: '15px',
                                            top: -8,
                                            fontWeight: 'bold',
                                            color: 'black',
                                            left: -10
                                        }}
                                    >
                                        Support
                                    </InputLabel>
                                    <Select
                                        value={selectedAgent || ticketData.assignee_agent_name}
                                        onChange={handleAgentChange}
                                        displayEmpty
                                        sx={{
                                            fontSize: '13px',
                                            height: '2.0rem',
                                            '.MuiSelect-select': {
                                                padding: '10px',
                                            },
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        {agents.length > 0 ? (
                                            agents.map((agent, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={agent}
                                                    sx={{ fontSize: '13px' }}
                                                >
                                                    {agent}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem disabled>No agents available</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Box>
                        </>
                    )}
                </Box>
            )}

            <Tabs value={value} sx={{textAlign:'left'}} onChange={handleChange} aria-label="Ticket Details Tabs">
                <Tab label="Conversations" />
                <Tab label="Tax Organizer" />
                <Tab label="Documents" />
                <Tab label="Draft" />
                <Tab label="Invoice" />
                <Tab label="Final Copies" />
            </Tabs>

            <Box sx={{ p: 1 }}>
                {value === 0 && <MessagesTab ticketId={ticketId} userType={userType} ticketData={ticketData} />}
                {value === 1 && <TaxOrganizerTab ticketId={ticketId} userType={userType} ticketData={ticketData} setTicketData={setTicketData} />}
                {value === 2 && <MyDocumentsTab ticketId={ticketId} userType={userType} ticketData={ticketData}/>}
                {value === 3 && <TaxDraftTab ticketId={ticketId} userType={userType} ticketData={ticketData}/>}
                {value === 4 && <InvoiceTab ticketId={ticketId} userType={userType} ticketData={ticketData} setTicketData={setTicketData} />}
                {value === 5 && <FinalDocumentsTab ticketId={ticketId} userType={userType} ticketData={ticketData}  />}
            </Box>

            {/* Status Confirmation Dialog */}
            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Confirm Status Update</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to update the ticket status to "{selectedStatus}"?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={confirmStatusUpdate} color="primary">Confirm</Button>
                </DialogActions>
            </Dialog>

            {/* Assignee Confirmation Dialog */}
            <Dialog open={openAgentDialog} onClose={handleDialogClose}>
                <DialogTitle>Confirm Assignee Update</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to assign this ticket to "{selectedAgent}"?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={confirmAgentUpdate} color="primary">Confirm</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default TicketData;
