import {Box, Button, Container, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {getValidAccessToken, handleLogout} from "../../utils/tokenUtils";
import axios from "axios";
import {useNavigate} from "react-router-dom";

export const LinksListView = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);

    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedOption, setSelectedOption] = useState('');
    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();



        try {
            const token = await getValidAccessToken();
            const response = await axios.get(`${apiUrl}/share-links?username=${selectedOption}`,

                {
                    headers: {
                        'Authorization': `Bearer ${token}`,

                    }
                });

            const { success, message } = response.data;

            if (success) {
              //  navigate('/login', { state: { message } });
            } else {
                //setErrorMessage(message);
            }
        } catch (error) {
            console.error(error.response);
           // setErrorMessage(error.response.data.message || 'An error occurred. Please try again.');

            //setError(error.response.data.message || 'Something went wrong. Please try again.');
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const token = await getValidAccessToken();
            if (token) {
                try {

                    const ticketsResponse = await axios.get(`${apiUrl}/list-users-by-type?user_type=support`, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });
                    setUsers(ticketsResponse.data);
                    console.log("Selected Option : "+ticketsResponse.data[0].username)
                    if(ticketsResponse.data.length > 0)
                        setSelectedOption((ticketsResponse.data[0].username))
                } catch (error) {
                    console.log('Error fetching data:', error);
                }
            } else {
                handleLogout();
            }
        };

        fetchData();
    }, [currentPage]);

    return (
        <Container>
            <Box sx={{ mt: 1, mb: 1 }}>
                <Typography variant="h4" align="center" gutterBottom>
                    Create Links for Support Users
                </Typography>

                <form onSubmit={handleSubmit}>
                    <select
                        id="fruit-select"
                        value={selectedOption} onChange={handleChange}
                        style={{  flex: 1,padding: '5px',fontSize:'16px' }}
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        size="large">

                        {users.map((user) => (
                            <option key={user.username} value={user.username}>
                                {user.username}
                            </option>
                        ))}

                    </select>

                    <TextField
                        label="link"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        name="links"
                        type="text"
                        inputProps={{ style: { fontSize: '14px' } }}
                    />


                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        sx={{ mt: 3 }}
                    >
                        Share Link
                    </Button>
                </form>
            </Box>
        </Container>
    )
}
