import React, { useEffect, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography, TablePagination } from '@mui/material';
import axios from 'axios';
import { getValidAccessToken, handleLogout } from '../utils/tokenUtils';
import { useNavigate } from 'react-router-dom';
import { useLoader } from '../context/LoaderContext'; // Importing the loader context

const apiUrl = process.env.REACT_APP_API_URL;


const GlobalMessages = ({ updateUnReadCount }) => {
    const [readMessages, setReadMessages] = useState([]);
    const [unreadMessages, setUnreadMessages] = useState([]);
    const [readPage, setReadPage] = useState(0);
    const [unreadPage, setUnreadPage] = useState(0);
    const navigate = useNavigate();
    const messagesPerPage = 5;
    const { showLoader, hideLoader } = useLoader(); // Use showLoader and hideLoader from context

    const fetchMessages = async (status) => {
        const token = await getValidAccessToken();
        if (!token) {
            handleLogout();
            return [];
        }
        try {
            showLoader(); // Show loader before API call

            const response = await axios.get(`${apiUrl}/global-messages/?message_status=${status}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            hideLoader(); // Hide loader after data is fetched

            return response.data.data;
        } catch (error) {
            console.error(`Error fetching ${status} messages:`, error);
            return [];
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const fetchedReadMessages = await fetchMessages('Read');
            const fetchedUnreadMessages = await fetchMessages('UnRead');
            setReadMessages(fetchedReadMessages);
            setUnreadMessages(fetchedUnreadMessages);
            updateUnReadCount(fetchedUnreadMessages.length); // Update unread count initially
        };

        fetchData();
    }, []);

    const handleMarkMessage = async (ticketMessageId, newStatus) => {
        const token = await getValidAccessToken();
        if (!token) {
            handleLogout();
            return;
        }
        try {
            await axios.post(`${apiUrl}/messages/mark-read-unread/`, {
                ticket_message_id: ticketMessageId,
                message_status: newStatus
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            const fetchedReadMessages = await fetchMessages('Read');
            const fetchedUnreadMessages = await fetchMessages('UnRead');
            setReadMessages(fetchedReadMessages);
            setUnreadMessages(fetchedUnreadMessages);
            updateUnReadCount(fetchedUnreadMessages.length); // Update unread count after status change
        } catch (error) {
            console.error('Error updating message status:', error);
        }
    };

    const handleTicketClick = (ticketId) => {
        navigate(`/ticket-data?ticket_id=${ticketId}`);
    };

    const handleChangePage = (event, newPage, isReadBlock) => {
        if (isReadBlock) {
            setReadPage(newPage);
        } else {
            setUnreadPage(newPage);
        }
    };

    const renderTable = (messages, isReadBlock) => {
        const page = isReadBlock ? readPage : unreadPage;
        const paginatedMessages = messages.slice(page * messagesPerPage, (page + 1) * messagesPerPage);

        return (
            <TableContainer component={Paper} sx={{ width: '100%' }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell  sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Ticket ID</TableCell>
                            <TableCell  sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Send Type</TableCell>
                            <TableCell  sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Sender</TableCell>
                            <TableCell  sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Message</TableCell>
                            <TableCell  sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Received On</TableCell>
                            <TableCell  sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedMessages.length > 0 ? (
                            paginatedMessages.map((message) => (
                                <TableRow
                                    key={message.ticket_message_id}
                                    sx={{
                                        backgroundColor: message.sender_type === 'user' ? 'lightblue' : 'lightgreen',
                                    }}
                                >
                                    <TableCell style={{ fontSize: '0.8rem' }}>
                                        <Button
                                            onClick={() => handleTicketClick(message.ticket_id)}
                                            sx={{ textDecoration: 'underline', color: 'secondary.main' }}
                                        >
                                            {message.ticket_id}
                                        </Button>
                                    </TableCell>
                                    <TableCell style={{ fontSize: '0.8rem' }}>{message.sender_type}</TableCell>
                                    <TableCell style={{ fontSize: '0.8rem' }}>
                                        {message.sender_type === 'user' ? message.username : message.agent_name || 'N/A'}
                                    </TableCell>
                                    <TableCell style={{ fontSize: '0.8rem' }}>{message.message_content || 'N/A'}</TableCell>
                                    <TableCell style={{ fontSize: '0.8rem' }}>{new Date(message.timestamp).toLocaleString()}</TableCell>
                                    <TableCell style={{ fontSize: '0.8rem' }}>
                                        <Button
                                            variant="contained"
                                            color={isReadBlock ? 'primary' : 'secondary'}
                                            onClick={() => handleMarkMessage(message.ticket_message_id, isReadBlock ? 'UnRead' : 'Read')}
                                            style={{ fontSize: '0.8rem' }}
                                        >
                                            {isReadBlock ? 'UnRead' : 'Read'}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={6} align="center" style={{ fontSize: '0.8rem' }}>
                                    No {isReadBlock ? 'Read' : 'Unread'} Messages
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[messagesPerPage]}
                    component="div"
                    count={messages.length}
                    rowsPerPage={messagesPerPage}
                    page={page}
                    onPageChange={(event, newPage) => handleChangePage(event, newPage, isReadBlock)}
                    sx={{
                        '& .MuiTablePagination-toolbar': {
                            minHeight: '30px',
                            padding: '0 8px',
                        },
                        '& .MuiTablePagination-selectLabel, & .MuiTablePagination-input, & .MuiTablePagination-displayedRows': {
                            fontSize: '0.8rem',
                        },
                        '& .MuiTablePagination-actions': {
                            marginLeft: '0',
                        },
                    }}
                />
            </TableContainer>
        );
    };

    return (
        <Box sx={{ padding: '20px' }}>
            <Box sx={{ marginBottom: '20px' }}>
                <Typography variant="h6" gutterBottom>
                    Unread Messages
                </Typography>
                {renderTable(unreadMessages, false)}
            </Box>
            <Box>
                <Typography variant="h6" gutterBottom>
                    Read Messages
                </Typography>
                {renderTable(readMessages, true)}
            </Box>
        </Box>
    );
};

export default GlobalMessages;