// src/components/Header.js
import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { handleLogout } from '../utils/tokenUtils';



const Header = () => {
    const location = useLocation();

    return (
        <AppBar position="static" sx={{ backgroundColor: 'secondary.main' }}>
            <Toolbar>
                {/* Add the logo image */}
                <Box sx={{ mr: 2 }}>
                    <img
                        src={`${process.env.PUBLIC_URL}/log3.png`}
                        alt="SaveTax LLC Logo"
                        style={{ height: '50px', width: 'auto' }}
                    />
                </Box>
                <Typography variant="h6" sx={{ flexGrow: 1, color: '#ff4700' }}>
                    
                </Typography>
                <Box>
                    {location.pathname === '/signup' ? (
                        <>
                            <Button color="inherit" component={Link} to="/login">
                                Login
                            </Button>
                        </>
                    ) : location.pathname === '/login' ? (
                        <>
                            <Button color="inherit" component={Link} to="/signup">
                                Signup
                            </Button>
                        </>
                    ) : location.pathname === '/home' || location.pathname === '/' ? (
                        <>
                        <Button color="inherit" component={Link} to="/refer-and-earn">
                                Refer & Earn
                            </Button>
                            <Button color="inherit" component={Link} to="/profile">
                                Profile
                            </Button>
                            <Button color="inherit" onClick={handleLogout}>
                                Logout
                            </Button>
                        </>
                    ) : location.pathname === '/create-ticket' || location.pathname === '/ticket-data' || location.pathname === '/refer-and-earn'  ? (
                        <>
                            <Button color="inherit" component={Link} to="/home">
                                Home
                            </Button>
                            <Button color="inherit" component={Link} to="/profile">
                                Profile
                            </Button>
                            <Button color="inherit" onClick={handleLogout}>
                                Logout
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button color="inherit" component={Link} to="/">
                                Home
                            </Button>
                            <Button color="inherit" component={Link} to="/signup">
                                Signup
                            </Button>
                            <Button color="inherit" component={Link} to="/login">
                                Login
                            </Button>
                        </>
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
