import React, { useEffect, useState } from 'react';
import axios from 'axios';
import InputMask from 'react-input-mask';
import { Checkbox, MenuItem, InputLabel, Select, FormControl, Box, Button, Grid, TextField, Typography, TextareaAutosize, Radio, RadioGroup, FormControlLabel, IconButton } from '@mui/material';
import { getValidAccessToken } from '../../../utils/tokenUtils';
import jsPDF from 'jspdf';
import RemoveIcon from '@mui/icons-material/Remove';
import autoTable from "jspdf-autotable";

const apiUrl = process.env.REACT_APP_API_URL;

const ExpensesForm = ({ userType, ticketData, setTicketData }) => {
    const [expensesForm, setExpensesForm] = useState({});
    const [loading, setLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');

    function toPascalCase(str) {
        return str.replace(/_/g, ' ')
            .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
                index === 0 ? match.toUpperCase() : match.toUpperCase()
            );
    }

    useEffect(() => {
        const fetchbookKeepingForm = async () => {
            try {
                const token = await getValidAccessToken();
                const response = await axios.post(
                    `${apiUrl}/tax-organizer-details/`,
                    {
                        tax_organizer_id: ticketData.tax_organizer_id,
                        column_names: 'expenses_details'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                const details = response.data.data.expenses_details;
                if (details) {
                    const parsedDetails = JSON.parse(details);
                    setExpensesForm(parsedDetails);
                    setDayCareValues(parsedDetails.dayCareValues || []);
                    setVehicleValues(parsedDetails.vehiclaValues || []);
                }
            } catch (error) {
                console.error('Error fetching business formation details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchbookKeepingForm();
    }, [ticketData.tax_organizer_id]);

    const handleSubmit = async () => {
        try {
            setSuccessMessage('');

            const token = await getValidAccessToken();
            const response = await axios.post(
                `${apiUrl}/update-taxorganizer-field/`,
                {
                    tax_organizer_id: ticketData.tax_organizer_id,
                    column_name: 'expenses_details',
                    column_value: JSON.stringify({ ...expensesForm, dayCareValues, vehiclaValues }),
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
           // console.log('Form saved successfully:', response.data);
            setSuccessMessage('Updated successfully!');
        } catch (error) {
            console.error('Error saving form:', error);
            setSuccessMessage('Error updating form. Please try again.');
        }
    };


/*    const handleDownloadPdf = () => {
        const doc = new jsPDF();
        doc.text('Partner Information Details', 10, 10);
        Object.entries(expensesForm || {}).forEach(([key, value], index) => {
            doc.text(`${key}: ${value}`, 10, 20 + index * 10);
        });
        doc.save('partner_information_details.pdf');
    };*/

    const removeArrays = (obj) => {
        // Use Object.entries to iterate over key-value pairs
        return Object.entries(obj).reduce((acc, [key, value]) => {
            if (!Array.isArray(value)) {
                // Only keep properties that are not arrays
                acc[key] = value;
            }
            return acc;
        }, {});
    };
    const toSentenceCase = (input) => {
        return input
            .split(/([.!?])\s*/) // Split on punctuation (., !, ?) with whitespace after
            .map(sentence => sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase()) // Capitalize each
            .join(''); // Rejoin sentences
    };
    const handleDownloadPdf = () => {
        const doc = new jsPDF();
        let y  = 10;
        let headValue ='Expences';
        let xOffset = (doc.internal.pageSize.width / 2) - (doc.getTextWidth(headValue) / 2);
        doc.text(headValue, xOffset, y);

        var img = new Image();
        img.src = "favicon.png";

        doc.addImage(img, 10, 2, 25, 25);

        const pageWidth = doc.internal.pageSize.width - 30;
        const twentyWidth = pageWidth * 0.2;
        const eigthWidth = pageWidth * 0.8;
        const sevenWidth = pageWidth * 0.75;
        const halfWidth = pageWidth * 0.5;


        let database = [];


        Object.entries(expensesForm || {}).forEach(([key, value], index) => {
          //  console.log("Key"+JSON.stringify(key))
          //  console.log("Value"+JSON.stringify(value))
            let row = [
                key,
                value
            ]
            database.push(row);
        });

        database = [
            [
                "",
                removeArrays(expensesForm)
            ],

        ];
        y  = 20;



        database.forEach(data => {
            let headValue =  data[0].toUpperCase();
            let xOffset = (doc.internal.pageSize.width / 2) - (doc.getTextWidth(headValue) / 2);
            doc.text(headValue, xOffset, y);

            let rows = [];
            let pos = 0;
            let rowData =JSON.parse(JSON.stringify(data[1]));
            const dataArray = Array.isArray(rowData) ? rowData : Object.entries(rowData);

            dataArray.forEach(item => {
                //  console.log("Data Array Rows : "+item);
                    rows[pos] = [toSentenceCase(item[0].replace(/_/g, ' ')),item[1].toUpperCase()];
                pos++;
            });

            // console.log("Data Array : "+rows);
            autoTable(doc, {
                startY: y + 10, // Starting position for the first table
                head: [['Fields', 'Value']],
                body:rows,
                headStyles: {halign: "center",fontStyle:"bold",textColor: 0, fillColor: [255,255,255], lineWidth: 0.2,lineColor: [0, 0, 0] },
                columnStyles: {
                    0:
                        {
                            halign: "left",
                            valign: 'middle',
                            fontStyle: "bold",
                            fontSize: 9,
                            textColor: "#000",
                            cellPadding: 1,
                            lineWidth: 0.2,
                            lineColor: [0, 0, 0],
                            cellWidth:eigthWidth
                        },
                    1: {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 9,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],
                        cellWidth:twentyWidth
                    },
                }
            });
            y = doc.lastAutoTable.finalY + 10;
            //y = y + 10;

        });




        doc.save('partner_information_details.pdf');
    };

    const DayCareFields = [
        { name: 'child_name', type: 'textbox' },
        { name: 'name_of_day_care', type: 'textbox' },
        { name: 'ein_or_ssn', type: 'number' },
        { name: 'address', type: 'textbox' },
        { name: 'amount', type: 'number' },
    ];


    const [dayCareValues, setDayCareValues] = useState([]);

    const handleAddDayCare = () => {
        setDayCareValues([...dayCareValues,
        {
            child_name: '',
            name_of_day_care: '',
            ein_or_ssn: '',
            address: '',
            amount: '',
        }
        ]);
    };
    
    const handleRemoveDayCare = (index) => {
        setDayCareValues(dayCareValues.filter((_, i) => i !== index));
    };

    const handleChangeDayCare = (index, field, value) => {
        const updatedPartners = [...dayCareValues];
        updatedPartners[index] = { ...updatedPartners[index], [field]: value };
        setDayCareValues(updatedPartners);
    };



    const vehicleFields = [
        { name: 'year', type: 'number' },
        { name: 'make', type: 'textbox' },
        { name: 'id_number', type: 'number' },
        { name: 'purchase_date', type: 'date' },
        { name: 'cost', type: 'number' },
    ];


    const [vehiclaValues, setVehicleValues] = useState([]);

    const handleAddVehicle = () => {
        setVehicleValues([...vehiclaValues,
        {
            year: '',
            make: '',
            id_number: '',
            purchase_date: '',
            cost: '',
        }
        ]);
    };

    
    const handleRemoveVehicle = (index) => {
        setVehicleValues(vehiclaValues.filter((_, i) => i !== index));
    };

    const handleChangevehicle = (index, field, value) => {
        const updatedPartners = [...vehiclaValues];
        updatedPartners[index] = { ...updatedPartners[index], [field]: value };
        setVehicleValues(updatedPartners);
    };




  




    const populateDynamicForm = ({ data, fields, handleChange,handleAdd,handleRemove }) => (
        <>
        {data.map((partner, index) => (
                <Box key={index} mb={0}>
                    

            <Grid container spacing={1} >
                {fields.map((field) => {
                    const isFullWidthField = field.type === 'radio' || field.type === 'textarea';
                    return (
                        <Grid item xs={12} sm={isFullWidthField ? 12 : 2.4} key={field.name}>
                            {field.type === 'textbox' ? (
                                <TextField
                                    label={toPascalCase(field.name)}
                                    inputProps={{ style: { fontSize: '16px' } }}
                                    value={partner[field.name]}
                                    onChange={(e) => handleChange( index, field.name, e.target.value)}
                                    fullWidth
                                    size="small"
                                    disabled={userType === 'agent'}
                                />
                            )  : field.type === 'number' ? (
                                <TextField
                                    type="number"
                                    label={toPascalCase(field.name)}
                                    inputProps={{ style: { fontSize: '16px' } }}
                                    value={partner[field.name]}
                                    onChange={(e) => handleChange( index,field.name, e.target.value)}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    disabled={userType === 'agent'}
                                />
                            ) : field.type === 'date' ? (
                                <TextField
                                    label={toPascalCase(field.name)}
                                    inputProps={{ style: { fontSize: '16px' } }}
                                    value={partner[field.name] || ''}
                                    onChange={(e) => handleChange( index,field.name, e.target.value)}
                                    fullWidth
                                    type="date"
                                    size="small"
                                    disabled={userType === 'agent'}
                                    InputLabelProps={{
                                        shrink: true, // Keep the label above the input
                                    }}
                                />
                            ) : null}
                        </Grid>
                        
                    );
                })}
                <Grid item xs={12} mb={0} mt={0}>
                    <IconButton onClick={() => handleRemove(index)}>
                        <RemoveIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Box>
    ))}
    <Button variant="outlined" color="primary" onClick={handleAdd} disabled={userType === 'agent'} >
        Add
    </Button>
</>
);

 

    const checkboxes = [
        {
            name: 'i_have_paid_home_mortgage_in_us_during_2023',
            label: 'I have paid Home Mortgage in US during 2023',
            upload: '1098'
        },
        {
            name: 'i_have_paid_home_mortgage_outside_us_during_2023',
            label: 'I have paid Home Mortgage outside US during 2023',
            upload: 'Loan Statement'
        },
        {
            name: 'i_have_paid_interest_on_student_loan_in_us_during_2023',
            label: 'I have paid Interest on Student / Education Loan in US during 2023',
            upload: '1098-E'
        },
        {
            name: 'i_have_incurred_education_expenses_in_us_during_2023',
            label: 'I have incurred Education Expenses for Higher Education in US during 2023',
            upload: '1098-T'
        },
        {
            name: 'i_have_purchased_energy_saving_equipment_in_us_during_2023',
            label: 'I have purchased Energy Saving Equipment in US during 2023',
            upload: 'Bills'
        },
        {
            name: 'i_have_invested_into_traditional_ira_during_2023',
            label: 'I have invested into a Traditional IRA during 2023',
            upload: '5498'
        },
        {
            name: 'i_have_invested_into_roth_ira_during_2023',
            label: 'I have invested into a Roth IRA during 2023',
            upload: 'Source Document'
        },
        {
            name: 'i_have_invested_into_hsa_fsa_during_2023',
            label: 'I have invested into a HSA / FSA during 2023',
            upload: '5498-SA'
        },
        {
            name: 'i_have_invested_into_esa_during_2023',
            label: 'I have invested into a ESA during 2023',
            upload: '5498-ESA'
        }
    ];


    const handleChangeCheckBoxes = (e) => {
        setExpensesForm({
            ...expensesForm,
            [e.target.name]: e.target.checked,
        });
    };


    const renderFormFields = () => (
        <Box mt={0} >



            <Grid container alignItems="center">
                <Grid item>
                    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', display: 'inline' }}>
                        Charitable Contributions during 2023:
                    </Typography>
                </Grid>
                <Grid item xs={6}>

                    <TextField type="number"  InputProps={{ inputProps: { min: 1},style: { fontSize: '16px' } }}  value={expensesForm?.charitable_contributions_during_2023 || ''} onChange={(e) => setExpensesForm({ ...expensesForm, charitable_contributions_during_2023: e.target.value })} size="small" disabled={userType === 'agent'} sx={{ marginLeft: '8px' }} />
                </Grid>

            </Grid>
            <Grid item xs={1} sx={{ mb: 1 }} /> {/* Adjust 'mb' value for more or less space */}


            <Grid container alignItems="center">
                <Grid item>
                    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', display: 'inline' }}>
                        Medical Expenses during 2023:
                    </Typography>
                </Grid>
                <Grid item xs={6}>

                    <TextField type="number" InputProps={{ inputProps: { min: 1},style: { fontSize: '16px' } }}  value={expensesForm?.medical_expenses_during_2023 || ''} onChange={(e) => setExpensesForm({ ...expensesForm, medical_expenses_during_2023: e.target.value })} size="small" disabled={userType === 'agent'} sx={{ marginLeft: '8px' }} />
                </Grid>
            </Grid>
            <Grid item xs={1} sx={{ mb: 1 }} /> {/* Adjust 'mb' value for more or less space */}


            <Grid container alignItems="center">
                <Grid item>
                    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', display: 'inline' }}>
                        Property Tax paid in US during 2023:
                    </Typography>
                </Grid>
                <Grid item xs={6}>

                    <TextField type="number" InputProps={{ inputProps: { min: 1},style: { fontSize: '16px' } }}  value={expensesForm?.property_tax_paid_in_us_during_2023 || ''} onChange={(e) => setExpensesForm({ ...expensesForm, property_tax_paid_in_us_during_2023: e.target.value })} size="small" disabled={userType === 'agent'} sx={{ marginLeft: '8px' }} />
                </Grid>
            </Grid>
            <Grid item xs={1} sx={{ mb: 1 }} /> {/* Adjust 'mb' value for more or less space */}

            <Grid container alignItems="center">
                <Grid item>
                    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', display: 'inline' }}>
                        Property Tax paid outside US during 2023:
                    </Typography>
                </Grid>
                <Grid item xs={6}>

                    <TextField type="number" InputProps={{ inputProps: { min: 1},style: { fontSize: '16px' } }}  value={expensesForm?.property_tax_paid_outside_us_during_2023 || ''} onChange={(e) => setExpensesForm({ ...expensesForm, property_tax_paid_outside_us_during_2023: e.target.value })} size="small" disabled={userType === 'agent'} sx={{ marginLeft: '8px' }} />
                </Grid>
            </Grid>

            <Grid item xs={1} sx={{ mb: 2 }} /> {/* Adjust 'mb' value for more or less space */}

            <Typography variant="body1" sx={{ fontSize: '20px', fontWeight: 'bold', marginRight: '8px' }}>
                <div>
                    <FormControlLabel
                        control={
                            <Checkbox
                            
                                checked='true'
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label="Check all the applicable boxes"
                    />
                </div>

            </Typography>


            <div>
                {checkboxes.map((checkbox) => (
                    <div key={checkbox.name}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    disabled={userType === 'agent'}
                                    checked={expensesForm[checkbox.name] || false}
                                    onChange={handleChangeCheckBoxes}
                                    name={checkbox.name}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label={checkbox.label}
                        />
                        <Typography variant="body1" sx={{ fontSize: '14px', marginRight: '8px' }}>
                            Upload: {checkbox.upload}
                        </Typography>
                    </div>
                ))}
            </div>

            <Grid container alignItems="center" spacing={1}>
                <Grid item>
                    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', marginRight: '8px' }}>
                        Have you incurred Day Care Expenses during 2023?
                    </Typography>
                </Grid>
                <Grid item>
                    <RadioGroup
                        row
                        value={expensesForm?.have_you_incurred_day_care_expenses_during_2023 || ''}
                        onChange={(e) => setExpensesForm({ ...expensesForm, have_you_incurred_day_care_expenses_during_2023: e.target.value })}
                    >
                        <FormControlLabel key="Yes" value="Yes" control={<Radio />} label="Yes" disabled={userType === 'agent'} />
                        <FormControlLabel key="No" value="No" control={<Radio />} label="No" disabled={userType === 'agent'} />

                    </RadioGroup>
                </Grid>
            </Grid>

            {expensesForm && expensesForm.have_you_incurred_day_care_expenses_during_2023 === 'Yes' && (
    
        populateDynamicForm({
            data: dayCareValues,           // form data
            fields: DayCareFields,         // array of field definitions
            handleChange: handleChangeDayCare,
            handleAdd: handleAddDayCare,
            handleRemove: handleRemoveDayCare // handle field change
        })
    
)}



            <Grid container alignItems="center" spacing={1}>
                <Grid item>
                    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', marginRight: '8px' }}>
                    Have you purchased Electric / Hybrid Car in US during 2023?
                    </Typography>
                </Grid>
                <Grid item>
                    <RadioGroup
                        row
                        value={expensesForm?.have_you_purchased_electric_or_hybrid_car_in_us_during_2023 || ''}
                        onChange={(e) => setExpensesForm({ ...expensesForm, have_you_purchased_electric_or_hybrid_car_in_us_during_2023: e.target.value })}
                    >
                        <FormControlLabel key="Yes" value="Yes" control={<Radio />} label="Yes" disabled={userType === 'agent'} />
                        <FormControlLabel key="No" value="No" control={<Radio />} label="No" disabled={userType === 'agent'} />

                    </RadioGroup>
                    
                </Grid>
                <Grid item><Typography variant="body1" sx={{ fontSize: '12px', marginRight: '10px' }}>
                (Note: If Yes, please upload source document)
            </Typography></Grid>

               
            </Grid>

            {expensesForm && expensesForm.have_you_purchased_electric_or_hybrid_car_in_us_during_2023 === 'Yes'  && 
            <>
            
  {populateDynamicForm({
    data: vehiclaValues,           // form data
    fields: vehicleFields,         // array of field definitions
    handleChange: handleChangevehicle,
    handleAdd:handleAddVehicle,
    handleRemove:handleRemoveVehicle // handle field change
  })}</>
}


            <Grid container alignItems="center" spacing={1}>
                <Grid item>
                    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', marginRight: '8px' }}>
                    Have you purchased (or) entered into a binding contract to buy a home in United States after April 8th, 2009 but before May 1st, 2010? 
                    </Typography>
                </Grid>
                <Grid item>
                    <RadioGroup
                        row
                        value={expensesForm?.have_you_purchased_or_entered_into_a_binding_contract_to_buy_a_home_in_united_states_after_april_8th_2009_but_before_may_1st_2010 || ''}
                        onChange={(e) => setExpensesForm({ ...expensesForm, have_you_purchased_or_entered_into_a_binding_contract_to_buy_a_home_in_united_states_after_april_8th_2009_but_before_may_1st_2010: e.target.value })}
                    >
                        <FormControlLabel key="Yes" value="Yes" control={<Radio />} label="Yes" disabled={userType === 'agent'} />
                        <FormControlLabel key="No" value="No" control={<Radio />} label="No" disabled={userType === 'agent'} />

                    </RadioGroup>
                </Grid>
            </Grid>

            {expensesForm && expensesForm.have_you_purchased_or_entered_into_a_binding_contract_to_buy_a_home_in_united_states_after_april_8th_2009_but_before_may_1st_2010 === 'Yes' && (   <Box mt={0} >

<Grid container alignItems="center" spacing={1}>
<Grid item>
    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', marginRight: '8px' }}>
    Is this the first home that you bought in US?
    </Typography>
</Grid>
<Grid item>
    <RadioGroup
        row
        value={expensesForm?.is_this_the_first_home_that_you_bought_in_us || ''}
        onChange={(e) => setExpensesForm({ ...expensesForm, is_this_the_first_home_that_you_bought_in_us: e.target.value })}
    >
        <FormControlLabel key="Yes" value="Yes" control={<Radio />} label="Yes" disabled={userType === 'agent'} />
        <FormControlLabel key="No" value="No" control={<Radio />} label="No" disabled={userType === 'agent'} />

    </RadioGroup>
</Grid>
</Grid>

<Grid container alignItems="center" spacing={1}>
<Grid item>
    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', marginRight: '8px' }}>
    Is this home used for your principal residence purposes?
    </Typography>
</Grid>
<Grid item>
    <RadioGroup
        row
        value={expensesForm?.is_this_home_used_for_your_principal_residence_purposes || ''}
        onChange={(e) => setExpensesForm({ ...expensesForm, is_this_home_used_for_your_principal_residence_purposes: e.target.value })}
    >
        <FormControlLabel key="Yes" value="Yes" control={<Radio />} label="Yes" disabled={userType === 'agent'} />
        <FormControlLabel key="No" value="No" control={<Radio />} label="No" disabled={userType === 'agent'} />

    </RadioGroup>
</Grid>
</Grid>


<Grid container alignItems="center" spacing={1}>
<Grid item>
    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', marginRight: '8px' }}>
    Have you owned a main home at any time during the three years immediately preceding the date of purchase of current home?
    </Typography>
</Grid>
<Grid item>
    <RadioGroup
        row
        value={expensesForm?.have_you_owned_a_main_home_at_any_time_during_the_three_years_immediately_preceding_the_date_of_purchase_of_current_home || ''}
        onChange={(e) => setExpensesForm({ ...expensesForm, have_you_owned_a_main_home_at_any_time_during_the_three_years_immediately_preceding_the_date_of_purchase_of_current_home: e.target.value })}
    >
        <FormControlLabel key="Yes" value="Yes" control={<Radio />} label="Yes" disabled={userType === 'agent'} />
        <FormControlLabel key="No" value="No" control={<Radio />} label="No" disabled={userType === 'agent'} />

    </RadioGroup>
</Grid>
</Grid>
<Grid container alignItems="center">
                <Grid item>
                    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', display: 'inline' }}>
                    Enter the cost price of such home :
                    </Typography>
                </Grid>
                <Grid item xs={6}>

                    <TextField type="number"   InputProps={{ inputProps: { min: 1},style: { fontSize: '16px' }, sx: { height: 40, padding: '5px 5px' }}}  value={expensesForm?.enter_the_cost_price_of_such_home || ''} onChange={(e) => setExpensesForm({ ...expensesForm, enter_the_cost_price_of_such_home: e.target.value })} size="small" disabled={userType === 'agent'} sx={{ marginLeft: '8px' }} />
                </Grid>
            </Grid>

<Grid container alignItems="center" spacing={1}>
<Grid item>
    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', marginRight: '8px' }}>
    Have you Ever claimed any First Time Home Buyer Credit?
    </Typography>
</Grid>


<Grid item>
    <RadioGroup
        row
        value={expensesForm?.have_you_ever_claimed_any_first_time_home_buyer_credit || ''}
        onChange={(e) => setExpensesForm({ ...expensesForm, have_you_ever_claimed_any_first_time_home_buyer_credit: e.target.value })}
    >
        <FormControlLabel key="Yes" value="Yes" control={<Radio />} label="Yes" disabled={userType === 'agent'} />
        <FormControlLabel key="No" value="No" control={<Radio />} label="No" disabled={userType === 'agent'} />

    </RadioGroup>
</Grid>
</Grid>



</Box>


            )}

            <TextareaAutosize
                minRows={3}
                placeholder="Comments"
                disabled={userType === 'agent'}
                value={expensesForm?.comments || ''}
                onChange={(e) => setExpensesForm({ ...expensesForm, comments: e.target.value })}
                style={{ width: '100%', fontSize: '16px', padding: '8px' }}
            />


            {(userType === 'user' || userType === 'admin') && (
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    {expensesForm ? 'Update' : 'Submit'}
                </Button>
            )}
            {successMessage && (
                <Typography color="success">{successMessage}</Typography>
            )}
        </Box>
    );

    if (loading) return <Typography>Loading...</Typography>;

    return (
        <Box p={0}>
            {userType === 'agent' ? (
                <>
                    {!expensesForm ? (
                        <Typography>Details are not provided</Typography>
                    ) : (
                        <>

                            {renderFormFields()}
                        </>
                    )}
                </>
            ) : (
                renderFormFields()
            )}
        </Box>
    );
};

export default ExpensesForm;
