import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, TextField, Button, Grid, Paper } from '@mui/material';
import axios from 'axios';
import { getValidAccessToken } from '../../utils/tokenUtils';

const apiUrl = process.env.REACT_APP_API_URL;

const MessagesTab = ({ ticketId, userType, ticketData }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const messagesEndRef = useRef(null);

    const [searchResults, setSearchResults] = useState([]);
  //  console.log("Ticket Data : "+JSON.stringify(ticketData))
    useEffect(() => {
        const fetchMessages = async () => {
            const token = await getValidAccessToken();
            try {
                const response = await axios.get(`${apiUrl}/tickets/messages/?ticket_id=${ticketId}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                if (response.data.success) {
                    setMessages(response.data.data);
                    scrollToBottom();
                } else {
                    setErrorMessage('Unable to fetch messages.');
                }
            } catch (error) {
                setErrorMessage('Error fetching messages.');
                console.error('Error fetching messages:', error);
            }
        };

        fetchMessages();
    }, [ticketId]);

    useEffect(() => {
        if (userType === 'agent') {
            const fetchUserInfo = async () => {
                try {
                    const token = await getValidAccessToken();
                    const response = await axios.get(`${apiUrl}/users/search/`, {
                        params: {
                            username: ticketData.username
                        },
                        headers: { 'Authorization': `Bearer ${token}` },
                    });

                    if (response.data.length === 0) {
                        setErrorMessage('No users found matching the criteria.');
                    } else {
                        setSearchResults(response.data);
                        setErrorMessage(null);
                    }
                } catch (error) {
                    console.error('Error fetching user details:', error);
                }
            };

            fetchUserInfo();
        }
    }, [ticketId]);

    const handleSendMessage = async (messageType = "Public") => {
        if (!newMessage.trim()) return; // Prevent sending empty messages

        const token = await getValidAccessToken();
        try {
            const response = await axios.post(`${apiUrl}/tickets/messages/`, {
                ticket_id: ticketId,
                message_content: newMessage,
                sender_type: userType,
                message_type: messageType
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            if (response.data.success) {
                setMessages([...messages, response.data.data]);
                setNewMessage('');
                scrollToBottom();
            } else {
                setErrorMessage('Unable to send message.');
            }
        } catch (error) {
            setErrorMessage('Error sending message.');
            console.error('Error sending message:', error);
        }
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSendMessage("Public");
        }
    };

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString(); // Formats the timestamp into a readable date and time
    };

    const userDetails = searchResults.length > 0 ? searchResults[0] : null;

    return (
        <Grid container spacing={2}>
            {/* Left side: Chat box */}
            <Grid item xs={(userType === 'agent' || userType === 'admin')   ? 8 : 12}>
                <Box>
                    {errorMessage && <Typography variant="body2" color="error">{errorMessage}</Typography>}
                    
                    <Box sx={{ p: 2, border: '1px solid #ccc', borderRadius: 1, mb: 2, maxHeight: '400px', overflowY: 'auto' }}>
                        {messages.length > 0 ? (
                            messages.map((message) => (
                                <Box key={message.ticket_message_id} sx={{ mb: 1 }}>
                                    {userType === 'agent' && (
                                        <Box sx={{ display: 'flex', justifyContent: (message.sender_type === 'user' ? 'flex-start' : 'flex-end') }}>
                                            <Typography variant="caption" color="textSecondary" sx={{ fontWeight: 'bold' }}>
                                                {message.sender_type === 'user' ? message.username : message.agentname}
                                            </Typography>
                                        </Box>
                                    )}
                                    <Box sx={{ display: 'flex', justifyContent: (userType === 'user' ? (message.sender_type === 'user' ? 'flex-end' : 'flex-start') : (message.sender_type === 'user' ? 'flex-start' : 'flex-end')) }}>
                                        <Typography variant="body2" sx={{ maxWidth: '70%', wordWrap: 'break-word', p: 1, backgroundColor: message.sender_type === 'user' ? '#d1ecf1' : '#f8d7da', borderRadius: 1 }}>
                                            {message.message_content}
                                            <Typography variant="caption" sx={{ display: 'block', fontSize: '0.75rem', color: '#888' }}>
                                                {formatTimestamp(message.timestamp)}
                                            </Typography>
                                            {message.message_type === "Private" && (
                                                <Typography variant="caption" sx={{ display: 'block', fontStyle: 'italic', fontSize: '0.75rem', color: '#ff5722', fontWeight: 'bold' }}>
                                                    (Sent Internally)
                                                </Typography>
                                            )}
                                        </Typography>
                                    </Box>
                                </Box>
                            ))
                        ) : <Typography variant="body2">No messages found.</Typography>}
                        <div ref={messagesEndRef} />
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            onKeyPress={handleKeyPress}
                            placeholder="Type your message..."
                            inputProps={{ style: { fontSize: '16px' } }}
                        />
                        <Button variant="contained" onClick={() => handleSendMessage("Public")}>Send</Button>
                        {userType === 'agent' && <Button variant="contained" onClick={() => handleSendMessage("Private")}>Send Internally</Button>}
                    </Box>
                </Box>
            </Grid>

            {/* Right side: User details and reason, visible only to agents */}
            {(userType === 'agent' || userType === 'admin') && (
                <Grid item xs={4}>
                    {/* User Details */}
                    <Paper elevation={3} sx={{ p: 2,mb:2, border: '1px solid #ccc', borderRadius: 1, backgroundColor: '#f8d7da' }}>
                        <Typography variant="h6" color="#333333"><strong>User Details:</strong></Typography>
                        {userDetails ? (
                            <Box >
                                <Typography color="#333333"><strong>Name:</strong> {userDetails.first_name} {userDetails.last_name}</Typography>
                                <Typography color="#333333"><strong>Username:</strong> {userDetails.username}</Typography>
                                <Typography color="#333333"><strong>Email:</strong> {userDetails.email}</Typography>
                                <Typography color="#333333"><strong>First Name:</strong> {userDetails.first_name}</Typography>
                                <Typography color="#333333"><strong>Last Name:</strong> {userDetails.last_name}</Typography>
                                <Typography color="#333333"><strong>Phone Name:</strong> {userDetails.phone_name}</Typography>

                             {/*}   <Typography><strong>Account Created:</strong> {new Date(userDetails.created_at).toLocaleDateString()}</Typography>
                                <Typography><strong>Last Login:</strong> {userDetails.last_login ? new Date(userDetails.last_login).toLocaleDateString() : 'Never'}</Typography>
                           */} </Box>
                        ) : (
                            <Typography>No user details available.</Typography>
                        )}
                    </Paper>

                    {/* Reason */}
                    {ticketData && ticketData.reason && (
                        <Box sx={{ p: 2, border: '1px solid #ccc', borderRadius: 1, backgroundColor: '#f8d7da' }}>
                            <Typography variant="body2" color="#333333">
                                <strong>Reason:</strong> {ticketData.reason}
                            </Typography>
                        </Box>
                    )}
                </Grid>
            )}
        </Grid>
    );
};

export default MessagesTab;
