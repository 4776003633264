import {
    Box, Container,
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {getValidAccessToken, handleLogout} from "../../utils/tokenUtils";
import axios from "axios";




import { useNavigate } from 'react-router-dom';

export const UserListView = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);

    const [username, setUsername] = useState('');
    const [emailId, setServiceName] = useState('');
    const [serviceYearValue, setServiceYearValue] = useState('');
    const [ticketStatus, setTicketStatus] = useState('');
    const [assigneeAgentName, setAssigneeAgentName] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [ticketId, setTicketId] = useState('');

    const [searchResults, setSearchResults] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);

    const handleTicketClick = (ticketId) => {
        navigate(`/user-register?user_id=${ticketId}`);
    };

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };
    const handleSearch = async () => {
        const token = await getValidAccessToken();
        if (!token) {
            handleLogout();
            return;
        }

        try {
            let currentUser = localStorage.getItem('username');
            const response = await axios.get(`${apiUrl}/users-referel?username=${currentUser}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });

            if (response.data.length === 0) {
                setErrorMessage('No users found matching the criteria.');
            } else {
                setUsers(response.data.data)
                setSearchResults(response.data);
                setErrorMessage(null);
            }
        } catch (error) {
            console.error('Error fetching users:', error);
            setErrorMessage(error.response.data.detail);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const token = await getValidAccessToken();
            if (token) {
                try {

                    const ticketsResponse = await axios.get(`${apiUrl}/users-referel?username=First`, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });
                    setUsers(ticketsResponse.data);
                    setTotalPages(Math.ceil(ticketsResponse.data.length / itemsPerPage));
                } catch (error) {
                    console.log('Error fetching data:', error);
                }
            } else {
                handleLogout();
            }
        };

        fetchData();
    }, [currentPage]);

    const indexOfLastTicket = currentPage * itemsPerPage;
    const indexOfFirstTicket = indexOfLastTicket - itemsPerPage;
    const currentUsers = Array.isArray(users) ? users.slice(indexOfFirstTicket, indexOfLastTicket) : [];
    return (
        <Container>
        <Box style={{ width: '100%', padding: '20px' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" marginTop="20px">
                <Typography variant="h5" align="left">
                    Users
                </Typography>

            </Box>

            <TableContainer component={Paper} style={{ marginTop: '20px',width:'100%' }}>
                <Table size='small' >
                    <TableHead>
                        <TableRow>



                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold',fontSize: '0.875rem'  }}>Ticket Id</TableCell>
                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold',fontSize: '0.875rem'  }}>Client Name</TableCell>
                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' ,fontSize: '0.875rem' }}>Email Id</TableCell>
                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' ,fontSize: '0.875rem' }}>Support</TableCell>
                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' ,fontSize: '0.875rem' }}>Process</TableCell>
                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' ,fontSize: '0.875rem' }}>E-Filling</TableCell>
                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' ,fontSize: '0.875rem' }}>Created On</TableCell>


                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentUsers && currentUsers.length > 0 ? (
                           currentUsers.map(users => (
                                <TableRow key={users.user_id} onClick={() => handleTicketClick(users.user_id)} style={{ cursor: 'pointer' }}>
                                    <TableCell>
                                        <a href="#" onClick={(e) => { e.preventDefault(); handleTicketClick(users.user_id); }}>
                                            {users.user_id}
                                        </a>
                                    </TableCell>
                                    <TableCell>{users.username}</TableCell>
                                    <TableCell>{users.email}</TableCell>
                                    <TableCell>{users.email}</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>

                                            {users.is_activated || 'N/A'}

                                    </TableCell>
                                    <TableCell>    {users.created_at}

                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={4} align="center">
                                    No tickets available
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
            />
        </Box>
        </Container>
    )
}
