import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {UserRegisterView} from "./admin/UserRegisterView";
import {LinksListView} from "./admin/LinksListView";
import {UserListView} from "./admin/UserListView";
import {Box, Tab, Tabs} from "@mui/material";


const statusTabs = [
    "Create Support users",
    "Create Links"
];


export const SupportHomeView = () => {
    const [tickets, setTickets] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(20);
    const [totalPages, setTotalPages] = useState(0);
    const [selectedStatus, setSelectedStatus] = useState(statusTabs[0]);
    const [statusCounts, setStatusCounts] = useState({}); // For storing ticket counts per status
    const navigate = useNavigate();

    const [unReadCount, setUnReadCount] = useState(0);
    const [noOfCustomers, setNoOfCustomers] = useState(0);

    const [noOfPendingCustomers, setNoOfPendingCustomers] = useState(0);
    const [notificationCount, setNotificationCount] = useState(0);

    const [activeTab, setActiveTab] = useState('Tab 1');

    const updateUnReadCount = (newCount) => {
        setUnReadCount(newCount);
    };

    const updateUnReadNotificationCount = (newCount) => {
        setNotificationCount(newCount);
    };



    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const handleTicketClick = (ticketId) => {
        navigate(`/ticket-data?ticket_id=${ticketId}`);
    };

    const handleTabChange = (event, newValue) => {
        setSelectedStatus(newValue);
        setCurrentPage(1); // Reset to first page when tab changes
    };

    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const validateBasicInfo = () => {
        const newErrors = {};
        if (!formData.username) newErrors.username = 'Username is required';
        if (!formData.email) newErrors.email = 'Email is required';
        return newErrors;
    };

    const validateAdditionalInfo = () => {
        const newErrors = {};
        if (!formData.password) newErrors.password = 'Password is required';
        if (formData.password !== formData.confirmPassword) {
            newErrors.confirmPassword = 'Passwords do not match';
        }
        return newErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors =
            activeTab === 'Basic Info' ? validateBasicInfo() : validateAdditionalInfo();

        if (Object.keys(validationErrors).length === 0) {
            //console.log('Form submitted:', formData);
            // Here, you would typically send formData to your server
            setFormData({username: '', email: '', password: '', confirmPassword: ''});
            setErrors({});
        } else {
            setErrors(validationErrors);
        }
    };

    const indexOfLastTicket = currentPage * itemsPerPage;
    const indexOfFirstTicket = indexOfLastTicket - itemsPerPage;
    const currentTickets = Array.isArray(tickets) ? tickets.slice(indexOfFirstTicket, indexOfLastTicket) : [];

    const [selectedOption, setSelectedOption] = useState('');

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const TabContent = ({ activeTab }) => {
        switch (activeTab) {
            case 'Create Support users':
                return <UserRegisterView />
            case 'Create Links':
                return <LinksListView />
            case 'Users View':
                return <UserListView />
            default:
                return null;
        }
    };

    return (
        <Box style={{ display: 'flex', padding: '10px' }}>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '220px',
                    paddingRight: '20px',
                    borderRight: '1px solid #ddd'
                }}
            >
                <Tabs
                    orientation="vertical"
                    value={selectedStatus}
                    onChange={handleTabChange}
                    aria-label="Vertical tabs"
                    variant="scrollable"
                >
                    <Tab label="Create Support users" isActive={activeTab === 'Create Support users'} onClick={() => setActiveTab('Create Support users')} />
                    <Tab label="Users View" isActive={activeTab === 'Users View'} onClick={() => setActiveTab('Users View')} />


                </Tabs>
            </Box>

            <Box  style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',

            }}>
                <div style={{ marginTop: '20px' }}>
                    <TabContent activeTab={activeTab} />
                </div>
            </Box>
        </Box>
    );
};

