import React, { useEffect, useState } from 'react';
import { Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Pagination, Tabs, Tab, Button } from '@mui/material';
import axios from 'axios';
import {getUserType, getValidAccessToken, handleLogout} from '../utils/tokenUtils';
import { useNavigate } from 'react-router-dom';
import  GlobalMessages from '../components/GlobalMessages';
import GlobalNotifications from './GlobalNotifications';
import Dummy from './dummy';
import ClientSearch from './ClientSearch';
import TicketSearch from './TicketSearch';

const apiUrl = process.env.REACT_APP_API_URL;


const statusTabs2 = [
    "Notification", "Messages", "Customer Search","Ticket Search", "Pending",
    "Express","Upfront Review", "Pending Info", "Under Tax Preparation", "Need more info",
    "Draft Sent", "Draft Rejected", "Draft Approved", "Payment made",
    "E-filing", "8879-sent", "8879-received", "e-filing complete",
    "ITIN", "ITIN Applied", "ITIN Received", "State Return Filed",
    "Paper Filing", "Final Copies Sent",  "FBAR",
    "FBAR Questionnaire sent", "FBAR Questionnaire Received", "FBAR Sent",
        "FBAR Closed", "BOIR", "BOIR Questionnaire Sent", "BOIR Questionnaire Received",
    "BOIR Sent", "BOIR Filed","Closed", "Walkout"
];

const AgentHomeView = () => {


    const [tickets, setTickets] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(20);
    const [statusTabs, setStatusTabs] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [selectedStatus, setSelectedStatus] = useState(0);
    const [userType, setUserType] = useState('');
    const [statusCounts, setStatusCounts] = useState({}); // For storing ticket counts per status
    const navigate = useNavigate();

    const [unReadCount, setUnReadCount] = useState(0);
    const [noOfCustomers, setNoOfCustomers] = useState(0);

    const [noOfPendingCustomers, setNoOfPendingCustomers] = useState(0);
    const [notificationCount, setNotificationCount] = useState(0);


    useEffect(() => {

        const fetchUserType = async () => {
            const type = await getUserType();
            setUserType(type);

        };


        const fetchData = async () => {
            const token = await getValidAccessToken();
            await fetchUserType();
            if (token) {
                try {
                    const ticketsResponse = await axios.get(`${apiUrl}/user-menus?user_type=agent`, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });
                    let permissionData = [];
                   // console.log(JSON.stringify(ticketsResponse.data))
                    const updatedStates =  ticketsResponse.data.map(item => {
                        permissionData.push(
                            item.menus
                            );}
                    );
                    setStatusTabs(permissionData);
                    if(permissionData.length > 0)
                        selectedStatus(statusTabs[0]);
                   // console.log("Selected Option : "+JSON.stringify(ticketsResponse.data))
                    //  if(ticketsResponse.data.length > 0)
                    //  setSelectedOption((ticketsResponse.data[0].username))
                } catch (error) {
                    console.log('Error fetching data:', error);
                }
            } else {
                handleLogout();
            }
        };

        fetchData();
    }, [currentPage]);

    const updateUnReadCount = (newCount) => {
        setUnReadCount(newCount);
    };

    const updateUnReadNotificationCount = (newCount) => {
        setNotificationCount(newCount);
    };



    useEffect(() => {
        const fetchData = async () => {
            const token = await getValidAccessToken();
            if (token) {
                try {
                    // Fetch count of tickets for each status
                    const countPromises = statusTabs.map(status => 
                        axios.get(`${apiUrl}/tickets/count/?ticket_status=${status}`, {
                            headers: { 'Authorization': `Bearer ${token}` }
                        }).then(response => {
                          //  console.log(`Count for ${status}:`, response.data); // Log response to verify
                            return {
                                status,
                                count: response.data.data.count // Adjust based on actual API response structure
                            };
                        })
                    );
                    const counts = await Promise.all(countPromises);
                    const countsMap = counts.reduce((acc, { status, count }) => {
                        acc[status] = count;
                        return acc;
                    }, {});
                    setStatusCounts(countsMap);

                    // Fetch tickets for the selected status
                    const ticketsResponse = await axios.get(`${apiUrl}/tickets/?ticket_status=${selectedStatus}`, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });
                    setTickets(ticketsResponse.data.data);
                    setTotalPages(Math.ceil(ticketsResponse.data.length / itemsPerPage));
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            } else {
                handleLogout();
            }
        };

        fetchData();
    }, [currentPage, selectedStatus]);

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const handleTicketClick = (ticketId) => {
        navigate(`/ticket-data?ticket_id=${ticketId}`);
    };

    const handleTabChange = (event, newValue) => {
        setSelectedStatus(newValue);
        setCurrentPage(1); // Reset to first page when tab changes
    };

    const indexOfLastTicket = currentPage * itemsPerPage;
    const indexOfFirstTicket = indexOfLastTicket - itemsPerPage;
    const currentTickets = Array.isArray(tickets) ? tickets.slice(indexOfFirstTicket, indexOfLastTicket) : [];
    const isArray = (variable) => Array.isArray(variable);
    return (
        <Box style={{ display: 'flex', padding: '10px' }}>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '220px',
                    paddingRight: '20px',
                    borderRight: '1px solid #ddd'
                }}
            >
                <Tabs
                    orientation="vertical"
                    value={selectedStatus}
                    onChange={handleTabChange}
                    aria-label="Vertical tabs"
                    variant="scrollable"
                >
                    {statusTabs != null && isArray(statusTabs) && statusTabs.map(status => (
                        <Tab
                            key={status}
                            value={status}
                            label={
                                <Box style={{ display: 'flex', alignItems: 'center'}}>
                                    <Typography style={{ fontSize: '11px', flexGrow: 1 }}>{status}</Typography>
                                    {status !== 'Customer Search' && status !== 'Ticket Search' && (
    (() => {
        if ((status === 'Messages' && unReadCount === 0) || (status === 'Notification' && notificationCount === 0)) {
            return null; // Do not render Typography if conditions are met
        }
        return (
            <Typography
                style={{
                    fontSize: '11px',
                    backgroundColor: selectedStatus === status ? '#0e1422' : '#f1f1f1',
                    borderRadius: '12px',
                    padding: '2px 6px',
                    marginLeft: '8px'
                }}
            >
                {(() => {
                    if (status === 'Messages') {
                        return unReadCount; // Display unReadCount if status is "Messages"
                    } else if (status === 'Notification') {
                        return notificationCount; // Display noOfCustomers if status is "Customers"
                    } else if (status === 'Customers') {
                        return noOfCustomers; // Display noOfCustomers if status is "Customers"
                    } else if (status === 'Pending Clients') {
                        return noOfPendingCustomers; // Display noOfPendingCustomers if status is "Pending Clients"
                    } else {
                        return statusCounts[status] || 0; // Default case
                    }
                })()}
            </Typography>
        );
    })()
)}
                                </Box>
                            }
                            sx={{ minHeight: '30px', height: '30px' }}
                            style={{
                                fontWeight: selectedStatus === status ? 'bold' : 'normal',
                                backgroundColor: selectedStatus === status ? '#ff4700' : 'transparent',
                                color: selectedStatus === status ? 'white' : 'black',
                                borderRadius: selectedStatus === status ? '4px' : '0',
                                marginBottom: '0px'
                            }}
                        />
                    ))}
                </Tabs>
            </Box>
            <Box style={{ flexGrow: 1, padding: '5px',width:"90%" }}>
    {selectedStatus === "Messages" ? (
        <GlobalMessages updateUnReadCount={updateUnReadCount} />
    ) : selectedStatus === "Notification" ? (
        <GlobalNotifications updateUnReadNotificationCount={updateUnReadNotificationCount} />
    ) : selectedStatus === "Customer Search" ? (
        <ClientSearch />
    ): selectedStatus === "Ticket Search" ? (
        <TicketSearch />
    ): selectedStatus === "Customers" ? (
        <Dummy updateUnReadNotificationCount={updateUnReadNotificationCount} />
    ):  selectedStatus === "Pending Clients" ? (
        <Dummy updateUnReadNotificationCount={updateUnReadNotificationCount} />
    ): (
        <Box style={{ padding: '10px', width: '120%' }}>
            <TableContainer component={Paper} style={{ marginTop: '0px'}}>
                <Table size="small">
                    <TableHead sx={{ '& .MuiTableCell-head': { height: '20px' } }}>
                        <TableRow>
                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Ticket ID</TableCell>
                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Customer Name</TableCell>
                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Tax Year</TableCell>
                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Service Name</TableCell>
                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Status</TableCell>
                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Assigned Agent</TableCell>
                            <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Support</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ '& .MuiTableCell-head': { height: '20px' } }}>
                        {currentTickets.length > 0 ? (
                            currentTickets.map(ticket => (
                                <TableRow
                                    key={ticket.ticket_id}
                                    onClick={() => handleTicketClick(ticket.ticket_id)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <TableCell>
                                        <Button
                                            onClick={() => handleTicketClick(ticket.ticket_id)}
                                            style={{ textDecoration: 'none', color: 'inherit' }}
                                        >
                                            {ticket.ticket_id}
                                        </Button>
                                    </TableCell>
                                    <TableCell>{ticket.first_name + " "+ticket.last_name}</TableCell>
                                    <TableCell>{ticket.service_year_value}</TableCell>
                                    <TableCell>{ticket.service_name}</TableCell>
                                    <TableCell>
                                        <Typography variant="body3" sx={{
                                            backgroundColor: '#ff4700',
                                            color: 'white',
                                            borderRadius: '16px',
                                            px: 1,
                                            py: 0.5,
                                            fontSize: '14px',
                                            top: '1px',
                                            fontWeight: 'bold'
                                        }}>
                                            {ticket.ticket_status || 'N/A'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>{ticket.assignee_agent_name || 'N/A'}</TableCell>
                                    <TableCell>{ticket.assignee_agent_name || 'N/A'}</TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={5} align="center">
                                    No tickets available
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
            />
        </Box>
    )}
</Box>

        </Box>
    );
};

export default AgentHomeView;
