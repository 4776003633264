import React, { useEffect, useState } from 'react';
import { Container, TextField, Button, Typography, Box, Alert } from '@mui/material';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth'; // Import the custom hook

const apiUrl = process.env.REACT_APP_API_URL;

const Signup = () => {
  useAuth();
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    reEnterPassword: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    referralCode: '', // Default empty
  });
  const location = useLocation();  // useLocation to access query params
  const [isReadOnly, setIsReadOnly] = useState(false);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);  // Parses the query string
    const referralCodeFromURL = queryParams.get('referralCode') || '';  // Get 'referralCode' from URL
  
    if (referralCodeFromURL) {
      const existingReferralCookie = getReferralCookie();
  
      // If there's no existing referral cookie or the existing one has expired, set the new one
      if (!existingReferralCookie || isReferralCodeExpired()) {
        setReferralCookie(referralCodeFromURL);  // Set the new referral code in cookies
        setFormData(prevData => ({
          ...prevData,
          referralCode: referralCodeFromURL,  // Set referralCode in the form
        }));
      } else {
        // If cookie is still valid, prevent overwriting and set the form with existing code
        setFormData(prevData => ({
          ...prevData,
          referralCode: existingReferralCookie,  // Use the existing valid referral code
        }));
      }
  
      setIsReadOnly(true);  // Make the referral code field read-only
    } else {
      // If no referral code in URL, check for cookie
      const referralCodeFromCookie = getReferralCookie();
      if (referralCodeFromCookie) {
        setFormData(prevData => ({
          ...prevData,
          referralCode: referralCodeFromCookie,  // Set referralCode from cookie
        }));
      }
    }
  }, [location.search]);
  

 // Retrieve the referral code from cookie
const getReferralCookie = () => {
  const cookies = document.cookie.split('; ');
  const referralCookie = cookies.find(row => row.startsWith('referral_code='));
  return referralCookie ? decodeURIComponent(referralCookie.split('=')[1]) : null;
};

// Check if the referral code has expired
const isReferralCodeExpired = () => {
  const expirationDate = localStorage.getItem('referral_code_expiration');
  if (!expirationDate) return true; // No expiration set, treat as expired

  const currentDate = new Date();
  const expiryDate = new Date(expirationDate);
  return currentDate > expiryDate;
};


// Set referral code in cookie and store expiration date in localStorage
const setReferralCookie = (code) => {
  const expires = new Date();
  expires.setDate(expires.getDate() + 10);  // 10 days expiration

  // Store only referral code as cookie
  document.cookie = `referral_code=${encodeURIComponent(code)}; expires=${expires.toUTCString()}; path=/`;

  // Store expiration date in localStorage
  localStorage.setItem('referral_code_expiration', expires.toISOString());
};



  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');

    if (formData.password !== formData.reEnterPassword) {
      setErrorMessage("Passwords don't match.");
      return;
    }

    try {
      const response = await axios.post(`${apiUrl}/signup/`, {
        username: formData.username,
        email: formData.email,
        password: formData.password,
        first_name: formData.firstName,
        last_name: formData.lastName || '',
        phone_number: formData.phoneNumber || '',
        user_type: 'user',
        referral_code: formData.referralCode || '',  // Include referralCode in POST
      });

      const { success, message } = response.data;

      if (success) {
        navigate('/login', { state: { message } });
      } else {
        setErrorMessage(message);
      }
    } catch (error) {
      console.error(error.response);
      setErrorMessage(error.response.data.message || 'An error occurred. Please try again.');
    }
  };

  return (
    <Container maxWidth="xs">
      <Box sx={{ mt: 1, mb: 1 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Sign Up
        </Typography>
        {errorMessage && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {errorMessage}
          </Alert>
        )}
        <form onSubmit={handleSubmit}>
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            margin="dense"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
            inputProps={{ style: { fontSize: '14px' } }}
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="dense"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            type="email"
            inputProps={{ style: { fontSize: '14px' } }}
          />
          <TextField
            label="Password"
            variant="outlined"
            fullWidth
            margin="dense"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
            type="password"
            inputProps={{ style: { fontSize: '14px' } }}
          />
          <TextField
            label="Re-enter Password"
            variant="outlined"
            fullWidth
            margin="dense"
            name="reEnterPassword"
            value={formData.reEnterPassword}
            onChange={handleChange}
            required
            type="password"
            inputProps={{ style: { fontSize: '14px' } }}
          />
          <TextField
            label="First Name"
            variant="outlined"
            fullWidth
            margin="dense"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
            inputProps={{ style: { fontSize: '14px' } }}
          />
          <TextField
            label="Last Name"
            variant="outlined"
            fullWidth
            margin="dense"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            inputProps={{ style: { fontSize: '14px' } }}
          />
          <TextField
            label="Phone Number"
            variant="outlined"
            fullWidth
            margin="dense"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            type="tel"
            inputProps={{ style: { fontSize: '14px' } }}
          />
          <TextField
            label="Invited By"
            variant="outlined"
            fullWidth
            margin="dense"
            name="referralCode"
            value={formData.referralCode}
            InputProps={{
              readOnly: isReadOnly,  // Make the field read-only if the referral code is present
            }}
            onChange={handleChange}
            inputProps={{ style: { fontSize: '14px' } }}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            type="submit"
            sx={{ mt: 3 }}
          >
            Create Account
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default Signup;
