import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {MenuItem, InputLabel,Grid,FormControl,Select,Alert, Box, Button, Container, TextField, Typography} from "@mui/material";
import {getValidAccessToken} from "../../utils/tokenUtils";

export const UserRegisterView = () => {

    const apiUrl = process.env.REACT_APP_API_URL;
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        reEnterPassword: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        userType:'',
        referralCode: '',
    });

    //const [error, setError] = useState('');
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        setSuccessMessage('');
        if (formData.password !== formData.reEnterPassword) {
            setErrorMessage("Passwords don't match.");
            return;
        }


        try {
            const token = await getValidAccessToken();
            const response = await axios.post(`${apiUrl}/admin-register`,
            {

                username: formData.username,
                email: formData.email,
                password: formData.password,
                first_name: formData.firstName,
                last_name: formData.lastName || '',
                phone_number: formData.phoneNumber || '',
                user_type:selectedOption || '',
                referral_code: formData.referralCode || '',
            },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                });

            const { success, message } = response.data;

            if (success) {
                setSuccessMessage('User Created successfully!');
            } else {
                setErrorMessage(message);
            }
        } catch (error) {
            console.error(error.response);
            setErrorMessage(error.response.data.message || 'An error occurred. Please try again.');

            //setError(error.response.data.message || 'Something went wrong. Please try again.');
        }
    };
    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };
    return (
        <Container maxWidth="xs">
            <Box sx={{ mt: 1, mb: 1 }}>
                <Typography variant="h4" align="center" gutterBottom>
                    New User Registration
                </Typography>
                {errorMessage && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {errorMessage}
                    </Alert>
                )}
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Username"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        required
                        inputProps={{ style: { fontSize: '14px' } }}
                    />
                    <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        type="email"
                        inputProps={{ style: { fontSize: '14px' } }}
                    />
                    <TextField
                        label="Password"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                        type="password"
                        inputProps={{ style: { fontSize: '14px' } }}
                    />
                    <TextField
                        label="Re-enter Password"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        name="reEnterPassword"
                        value={formData.reEnterPassword}
                        onChange={handleChange}
                        required
                        type="password"
                        inputProps={{ style: { fontSize: '14px' } }}
                    />
                    <TextField
                        label="First Name"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                        inputProps={{ style: { fontSize: '14px' } }}
                    />
                    <TextField
                        label="Last Name"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        inputProps={{ style: { fontSize: '14px' } }}
                    />
                    <TextField
                        label="Phone Number"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        type="tel"
                        inputProps={{ style: { fontSize: '14px' } }}
                    />

<Typography variant="h4" align="center" gutterBottom>
                </Typography>

                    <Grid item xs>
                                                <FormControl fullWidth variant="outlined" size="small" >
                                                    <InputLabel>User Type</InputLabel>
                                                    <Select
                                                    sx={{
                                                        '& .MuiSelect-select': {
                                                            fontSize: '16px', // Increase font size for the selected value
                                                        },
                                                    }}
                                                        value={selectedOption}
                                                        onChange={handleSelectChange}
                                                        label="User Type"
                                                    >
                                                            <MenuItem key="Admin" value="Admin" sx={{ fontSize: '1.0 rem' }}>
                                                                Admin
                                                            </MenuItem>
                                                            <MenuItem key="Tax_Payerer" value="Tax_Payerer" sx={{ fontSize: '1.0 rem' }}>
                                                                Tax_Payerer
                                                            </MenuItem>
                                                            <MenuItem key="CSR" value="CSR" sx={{ fontSize: '1.0 rem' }}>
                                                                CSR
                                                            </MenuItem>
                                                            <MenuItem key="E_Filer" value="E_Filer" sx={{ fontSize: '1.0 rem' }}>
                                                                E_Filer
                                                            </MenuItem>
                                                            <MenuItem key="P_Filer" value="P_Filer" sx={{ fontSize: '1.0 rem' }}>
                                                                P_Filer
                                                            </MenuItem>
                                                            <MenuItem key="Fbar_Filer" value="Fbar_Filer" sx={{ fontSize: '1.0 rem' }}>
                                                                Fbar_Filer
                                                            </MenuItem>
                                                       
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        sx={{ mt: 3 }}
                    >
                        Create Account
                    </Button>
                    {successMessage && (
                        <Typography color="success">{successMessage}</Typography>
                    )}
                </form>
            </Box>
        </Container>
    )
}
