// src/components/ClientSearch.js
import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import axios from 'axios';
import { getValidAccessToken, handleLogout } from '../utils/tokenUtils'; // Assuming this function is available
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

const ClientSearch = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [isActivated, setIsActivated] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();

    const handleSearch = async () => {
        const token = await getValidAccessToken();
        if (!token) {
            handleLogout();
            return;
        }

        try {
            const response = await axios.get(`${apiUrl}/users/search/`, {
                params: {
                    username,
                    email,
                    phone_number: phoneNumber,
                    first_name: firstName,
                    last_name: lastName,
                    is_activated:isActivated,
                },
                headers: { 'Authorization': `Bearer ${token}` },
            });

            if (response.data.length === 0) {
                setErrorMessage('No users found matching the criteria.');
            } else {
                setSearchResults(response.data);
                setErrorMessage(null);
            }
        } catch (error) {
            console.error('Error fetching users:', error);
            setErrorMessage(error.response.data.detail);
        }
    };

    return (
        <Box style={{ padding: '10px', width: '120%' }}>
            <Typography sx={{fontWeight: 'bold' }} variant="h5" align="left" gutterBottom>
                Client Search
            </Typography>

            {/* First Line - Username, First Name, Last Name */}
            <Box display="flex" justifyContent="space-between" marginBottom={0} flexWrap="wrap" gap={2}>
                <TextField
                    label="Username"
                    variant="outlined"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    style={{ flex: 1, fontSize: '1.1rem' }}
                    inputProps={{ style: { fontSize: '18px' } }}
                    fullWidth
                    margin="dense"
                    size="small" // Decrease textbox size
                />
                <TextField
                    label="First Name"
                    variant="outlined"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    style={{ flex: 1, fontSize: '1.1rem' }}
                    inputProps={{ style: { fontSize: '18px' } }}
                    fullWidth
                    margin="dense"
                    size="small" // Decrease textbox size
                />
                <TextField
                    label="Last Name"
                    variant="outlined"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    style={{ flex: 1, fontSize: '1.1rem' }}
                    inputProps={{ style: { fontSize: '18px' } }}
                    fullWidth
                    margin="dense"
                    size="small" // Decrease textbox size
                />
            </Box>

            {/* Second Line - Phone Number, Email, Search Button */}
            <Box display="flex" justifyContent="space-between" flexWrap="wrap" gap={2}>
                <TextField
                    label="Phone Number"
                    variant="outlined"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    style={{ flex: 1, fontSize: '1.1rem' }}
                    inputProps={{ style: { fontSize: '18px' } }}
                    fullWidth
                    margin="dense"
                    size="small" // Decrease textbox size
                />
                <TextField
                    label="Email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{ flex: 1, fontSize: '1.1rem' }}
                    inputProps={{ style: { fontSize: '18px' } }}
                    fullWidth
                    margin="dense"
                    size="small" // Decrease textbox size
                />
                 <TextField
                    label="Is Active"
                    variant="outlined"
                    value={isActivated}
                    onChange={(e) => setIsActivated(e.target.value)}
                    style={{ flex: 1, fontSize: '1.1rem' }}
                    inputProps={{ style: { fontSize: '18px' } }}
                    fullWidth
                    margin="dense"
                    size="small" // Decrease textbox size
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSearch}
                    style={{ height: '40px', alignSelf: 'center' }}
                >
                    Search
                </Button>
            </Box>

            {errorMessage && (
                <Typography variant="body1" color="error" align="center" marginTop={2}>
                    {errorMessage}
                </Typography>
            )}

            {searchResults.length > 0 && (
                <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                    <Table  size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' ,fontSize: '0.875rem' }}>Username</TableCell>
                                <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' ,fontSize: '0.875rem' }}>Email</TableCell>
                                <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' ,fontSize: '0.875rem' }}>Phone Number</TableCell>
                                <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' ,fontSize: '0.875rem' }}>First Name</TableCell>
                                <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' ,fontSize: '0.875rem' }}>Last Name</TableCell>
                                <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' ,fontSize: '0.875rem' }}>Is Activated</TableCell>
                                <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' ,fontSize: '0.875rem' }}>Created On</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {searchResults.map((user) => (
                                <TableRow key={user.user_id}>
                                    <TableCell>{user.username}</TableCell>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>{user.phone_number || 'N/A'}</TableCell>
                                    <TableCell>{user.first_name || 'N/A'}</TableCell>
                                    <TableCell>{user.last_name || 'N/A'}</TableCell>
                                    <TableCell>{user.is_activated ? 'Yes' : 'No'}</TableCell>
                                    <TableCell>{new Date(user.created_at).toLocaleString()}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
};

export default ClientSearch;
