import React, { useEffect, useState } from 'react';
import axios from 'axios';
import InputMask from 'react-input-mask';
import { Box, Button, Grid, TextField, Typography, TextareaAutosize, Radio, RadioGroup, FormControlLabel, IconButton } from '@mui/material';
import { getValidAccessToken } from '../../../utils/tokenUtils';
import jsPDF from 'jspdf';
import RemoveIcon from '@mui/icons-material/Remove';

const apiUrl = process.env.REACT_APP_API_URL;

const PartnerDetailsForm = ({ userType, ticketData, setTicketData }) => {
  const [partnerDetailsForm, setPartnerDetailsForm] = useState(null);
  const [loading, setLoading] = useState(true);
  const [partners, setPartners] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');


  function toPascalCase(str) {
    return str.replace(/_/g, ' ')
      .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
        index === 0 ? match.toUpperCase() : match.toUpperCase()
      );

  }

  useEffect(() => {
    const fetchPartnerDetailsForm = async () => {
      try {
        const token = await getValidAccessToken();
        const response = await axios.post(
          `${apiUrl}/tax-organizer-details/`,
          {
            tax_organizer_id: ticketData.tax_organizer_id,
            column_names: 'shareholder_details'
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        const details = response.data.data.shareholder_details;
        if (details) {
          const parsedDetails = JSON.parse(details);
          setPartnerDetailsForm(parsedDetails);
          setPartners(parsedDetails.partners || []);
        }
      } catch (error) {
        console.error('Error fetching business formation details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPartnerDetailsForm();
  }, [ticketData.tax_organizer_id]);

  const handleSubmit = async () => {
    try {
      setSuccessMessage('');

      const token = await getValidAccessToken();
      const response = await axios.post(
        `${apiUrl}/update-taxorganizer-field/`,
        {
          tax_organizer_id: ticketData.tax_organizer_id,
          column_name: 'shareholder_details',
          column_value: JSON.stringify({ ...partnerDetailsForm, partners }),
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
     // console.log('Form saved successfully:', response.data);
      setSuccessMessage('Updated successfully!');
    } catch (error) {
      console.error('Error saving form:', error);
      setSuccessMessage('Error updating form. Please try again.');
    }
  };

  const handleDownloadPdf = () => {
    const doc = new jsPDF();
    doc.text('Partner Information Details', 10, 10);
    //console.log(JSON.stringify(partnerDetailsForm))
    Object.entries(partnerDetailsForm || {}).forEach(([key, value], index) => {
      doc.text(`${key}: ${value}`, 10, 20 + index * 10);
    });
    doc.save('partner_information_details.pdf');
  };

  const handleAddPartner = () => {
    setPartners([...partners,
    {
      type_of_shareholder_or_member_or_partner: 'Individual',
      first_name: '',
      middle_name: '',
      last_name: '',
      ssn_or_ein: '',
      address_line1: '',
      address_line2: '',
      city: '',
      state: '',
      zip_code: '',
      phone_no: '',
      email: '',
      percentage_of_ownership_at_start_of_year: '0',
      percentage_of_ownership_at_transferred: '0',
      percentage_of_ownership_at_end_of_year: '0',
      guaranteed_payments_for_year: 'Yes',
      signing_officer_or_partner_or_member: 'Yes',
    }
    ]);
  };

  const handleRemovePartner = (index) => {
    setPartners(partners.filter((_, i) => i !== index));
  };

  const handlePartnerChange = (index, field, value) => {
    const updatedPartners = [...partners];
    updatedPartners[index] = { ...updatedPartners[index], [field]: value };
    setPartners(updatedPartners);
  };

  const fields = [
    { name: 'type_of_shareholder_or_member_or_partner', type: 'radio', options: ['Entity', 'Individual'] },
    { name: 'ssn_or_ein', type: 'ssn' },
    { name: 'first_name', type: 'textbox' },
    { name: 'middle_name', type: 'textbox' },
    { name: 'last_name', type: 'textbox' },
    { name: 'address_line1', type: 'textarea' },
    { name: 'address_line2', type: 'textarea' },
    { name: 'city', type: 'textbox' },
    { name: 'state', type: 'textbox' },
    { name: 'zip_code', type: 'textbox' },
    { name: 'phone_no', type: 'textbox' },
    { name: 'email', type: 'email' },
    { name: 'percentage_of_ownership_at_start_of_year', type: 'number' },
    { name: 'percentage_of_ownership_at_transferred', type: 'number' },
    { name: 'percentage_of_ownership_at_end_of_year', type: 'number' },
    { name: 'guaranteed_payments_for_year', type: 'radio', options: ['Yes', 'No', 'NA'] },
    { name: 'signing_officer_or_partner_or_member', type: 'radio', options: ['Yes', 'No'] }
  ];

  const renderPartners = () => (
    <>
      {partners.map((partner, index) => (
        <Box key={index} mb={0}>
          <Grid container spacing={1} >
            {fields.map((field) => {
              const isFullWidthField = field.type === 'radio' || field.type === 'textarea';
              return (
                <Grid item xs={12} sm={isFullWidthField ? 12 : 6} key={field.name}>
                  {field.type === 'ssn' || field.type === 'ein' ? (
                    <InputMask
                    mask={partner['type_of_shareholder_or_member_or_partner'] === 'Entity' ? '99-9999999' : '999-99-9999'} // EIN or SSN mask
                      inputProps={{ style: { fontSize: '16px' } }}
                      value={partner[field.name]}
                      onChange={(e) => handlePartnerChange(index, field.name, e.target.value)}
                      disabled={userType === 'agent'}
                    >
                      {(inputProps) => <TextField {...inputProps} label={toPascalCase(field.name)} fullWidth size="small" variant="outlined" />}
                    </InputMask>
                  ) : field.type === 'textbox' ? (
                    <TextField
                      label={toPascalCase(field.name)}
                      inputProps={{ style: { fontSize: '16px' } }}
                      value={partner[field.name]}
                      onChange={(e) => handlePartnerChange(index, field.name, e.target.value)}
                      fullWidth
                      size="small"
                      disabled={userType === 'agent'}
                    />
                  ) : field.type === 'textarea' ? (
                    <TextareaAutosize
                      inputProps={{ style: { fontSize: '16px' } }}
                      minRows={3}
                      placeholder={toPascalCase(field.name)}
                      value={partner[field.name]}
                      onChange={(e) => handlePartnerChange(index, field.name, e.target.value)}
                      style={{ width: '100%', fontSize: '16px', padding: '8px' }}
                      disabled={userType === 'agent'}
                    />
                  ) : field.type === 'number' ? (
                    <TextField
                      type="number"
                      label={toPascalCase(field.name)}
                      inputProps={{ style: { fontSize: '16px' } }}
                      value={partner[field.name]}
                      onChange={(e) => handlePartnerChange(index, field.name, e.target.value)}
                      fullWidth
                      size="small"
                      variant="outlined"
                      disabled={userType === 'agent'}
                    />
                  ) : field.type === 'radio' ? (
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                      <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', display: 'inline' }}>
                      {toPascalCase(field.name)}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <RadioGroup
                          row
                          value={partner[field.name]}
                          onChange={(e) => handlePartnerChange(index, field.name, e.target.value)}
                        >
                          {field.options.map((option) => (
                            <FormControlLabel key={option} value={option} control={<Radio />} label={option} disabled={userType === 'agent'} />
                          ))}
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  ) : field.type === 'email' ? (
                    <TextField
                      label={toPascalCase(field.name)}
                      inputProps={{ style: { fontSize: '16px' } }}
                      value={partner[field.name]}
                      onChange={(e) => handlePartnerChange(index, field.name, e.target.value)}
                      fullWidth
                      type="email"
                      size="small"
                      disabled={userType === 'agent'}
                    />
                  ) : null}
                </Grid>
              );
            })}
            <Grid item xs={12} mb={0} mt={0}>
              <IconButton onClick={() => handleRemovePartner(index)}>
                <RemoveIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      ))}
      <Button variant="outlined" color="primary" onClick={handleAddPartner} disabled={userType === 'agent'} >
        Add Partner
      </Button>
    </>
  );

  const renderFormFields = () => (
    <Box mt={0} >
      {/* Render Partners */}
      {renderPartners()}
      {/* Entity Type */}
      <TextareaAutosize

        minRows={3}
        placeholder="Percentage Sharing Equal"
        value={partnerDetailsForm?.percentage_sharing_equal || ''}
        onChange={(e) => setPartnerDetailsForm({ ...partnerDetailsForm, percentage_sharing_equal: e.target.value })}
        style={{ width: '100%', fontSize: '16px', padding: '8px' }}
        
      />
      <TextareaAutosize
        minRows={3}
        placeholder="Non-Contributing Partner Details"
        value={partnerDetailsForm?.non_contributing_partner_details || ''}
        onChange={(e) => setPartnerDetailsForm({ ...partnerDetailsForm, non_contributing_partner_details: e.target.value })}
        style={{ width: '100%', fontSize: '16px', padding: '8px' }}
      />
      {userType === 'user' && (
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          {partnerDetailsForm ? 'Update' : 'Submit'}
        </Button>
      )}
      {successMessage && (
        <Typography color="success">{successMessage}</Typography>
      )}
    </Box>
  );

  if (loading) return <Typography>Loading...</Typography>;

  return (
    <Box p={0}>
      {userType === 'agent' ? (
        <>
          {!partnerDetailsForm ? (
            <Typography>Details are not provided</Typography>
          ) : (
            <>

              {renderFormFields()}
            </>
          )}
        </>
      ) : (
        renderFormFields()
      )}
    </Box>
  );
};

export default PartnerDetailsForm;
