// tokenUtils.js
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

// Function to get the stored token from localStorage
export const getAccessToken = () => localStorage.getItem('access_token');
export const getRefreshToken = () => localStorage.getItem('refresh_token');
export const getUserName = () => localStorage.getItem('username');
export const getUserType = () => localStorage.getItem('user_type');

const apiUrl = process.env.REACT_APP_API_URL;


// Function to store tokens in localStorage
export const storeTokens = (accessToken, refreshToken,userName,userType) => {
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('refresh_token', refreshToken);
    localStorage.setItem('username', userName);
    localStorage.setItem('user_type', userType);

 //   console.log("user:",userName);
//   console.log("userType:",userType);

};

// Function to remove tokens from localStorage
export const removeTokens = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('username');
    localStorage.removeItem('user_type');
};

// Function to decode token and get expiration time
export const getTokenExpiry = (token) => {
    const decodedToken = jwtDecode(token);
    return decodedToken.exp * 1000; // Convert to milliseconds
};

// Function to check if token is expired
export const isTokenExpired = (token) => {
    const expiryTime = getTokenExpiry(token);
    return Date.now() > expiryTime;
};

// Function to refresh access token
export const refreshAccessToken = async () => {
    const refreshToken = getRefreshToken();

    try {
        const response = await axios.post(`${apiUrl}/token/refresh/`, { refresh_token: refreshToken });
        const { access } = response.data;
        storeTokens(access, refreshToken);
        return access;
    } catch (error) {
        console.error('Token refresh failed:', error);
        //removeTokens();
        handleLogout();
        //return null;
    }
};

// Function to get a valid access token
export const getValidAccessToken = async () => {
    let accessToken = getAccessToken();

    if (isTokenExpired(accessToken)) {
        accessToken = await refreshAccessToken();
    }

    return accessToken;
};

// Function to handle logout
export const handleLogout = () => {
    removeTokens();
    window.location.href = '/login'; // Redirect to login page
};

// Function to check if email, access token, and refresh token are present
export const areTokensAndEmailPresent = () => {
    const accessToken = getAccessToken();
    const refreshToken = getRefreshToken();
    const userName = getUserName();
    const userType = getUserType();
    return accessToken && refreshToken && userName && userType;
};
