// src/hooks/useAuth.js
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAccessToken } from '../utils/tokenUtils';



const useAuth = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const accessToken = getAccessToken();

    useEffect(() => {
      //  console.error("current page:", location.pathname);
        const isLoginPage = location.pathname === '/login';
        const isSignupPage = location.pathname === '/signup';

        if (accessToken) {
            // User is logged in
            if (isLoginPage || isSignupPage) {

               navigate('/home'); // Redirect to home if on login or signup page
            }
        } else {
            // User is not logged in
            if (!isLoginPage && !isSignupPage) {
                navigate('/login', { state: { message: 'You are not logged in. Please log in to continue.' } }); // Redirect to login with message
            }
        }
    }, [navigate, location.pathname, accessToken]);
};

export default useAuth;
