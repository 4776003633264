import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {getValidAccessToken, handleLogout} from "../../utils/tokenUtils";
import axios from "axios";
import {
    Box,
    Button,
    Container, Grid, Pagination,
    Paper, Switch,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";

export const UserPermission = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [permission, setPermission] = useState([]);
    const [userType, setUserType] = useState(['admin']);
    const [token, setToken] = useState(['admin']);


    const [switchStates, setSwitchStates] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [successMessage, setSuccessMessage] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedOption, setSelectedOption] = useState('');



    const handleInputChange = (event) => {
        setSelectedOption(event.target.value);
        setUserType(event.target.value);
        event.preventDefault();

      /*  const fetchData = async () => {
            const token = await getValidAccessToken();
            setToken(token);
            if (token) {
                try {
                    const ticketsResponse = await axios.get(`${apiUrl}/users-permission?user_type=${value}`, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });
                    let permissionData = [];
                  //  console.log(ticketsResponse.data)
                    const updatedStates =  ticketsResponse.data.map(item => {
                        permissionData.push({"permission_id":item.permission_id,
                            "menus":item.menus,
                            "state":item.state == 0 ? false : true});}
                    );
                    setPermission(permissionData);
                    //console.log("Selected Option : "+JSON.stringify(ticketsResponse.data))
                    //  if(ticketsResponse.data.length > 0)
                    //  setSelectedOption((ticketsResponse.data[0].username))
                } catch (error) {
                    console.log('Error fetching data:', error);
                }
            } else {
                handleLogout();
            }
        };

        fetchData();*/


    };


        const fetchPermission = async () => {
            const token = await getValidAccessToken();

            if (token) {
                try {
                    const ticketsResponse = await axios.get(`${apiUrl}/users-permission?user_type=${userType}`, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });
                    let permissionData = [];
                    //  console.log(ticketsResponse.data)
                    const updatedStates =  ticketsResponse.data.map(item => {
                        permissionData.push({"permission_id":item.permission_id,
                            "menus":item.menus,
                            "state":item.state == 0 ? false : true});}
                    );
                    setPermission(permissionData);
                    //console.log("Selected Option : "+JSON.stringify(ticketsResponse.data))
                    //  if(ticketsResponse.data.length > 0)
                    //  setSelectedOption((ticketsResponse.data[0].username))
                } catch (error) {
                    console.log('Error fetching data:', error);
                }
            } else {
                handleLogout();
            }
        };





    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setSuccessMessage('');
            console.log("Submit ==== > "+JSON.stringify(permission))
            const token = await getValidAccessToken();

            await axios.post(`${apiUrl}/create-users-permission?user_type=${userType}`,
                {
                    permission
                },
                {
                    headers: { Authorization: `Bearer ${token}` }}
                );
            setSuccessMessage('updated successfully!');


        } catch (error) {
            console.error(error.response);
            setSuccessMessage('Error updating form. Please try again.');
            // setErrorMessage(error.response.data.message || 'An error occurred. Please try again.');

            //setError(error.response.data.message || 'Something went wrong. Please try again.');
        }
    };

    /*useEffect(() => {

        const fetchData = async () => {
            const token = await getValidAccessToken();
            setToken(token);
            if (token) {
                try {
                    const ticketsResponse = await axios.get(`${apiUrl}/users-permission?user_type=${userType}`, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });
                    let permissionData = [];
                   // console.log(ticketsResponse.data)
                    const updatedStates =  ticketsResponse.data.map(item => {
                       permissionData.push({"permission_id":item.permission_id,
                           "menus":item.menus,
                            "state":item.state == 0 ? false : true});}
                    );
                    setPermission(permissionData);
                    console.log("Selected Option : "+JSON.stringify(ticketsResponse.data))
                  //  if(ticketsResponse.data.length > 0)
                      //  setSelectedOption((ticketsResponse.data[0].username))
                } catch (error) {
                    console.log('Error fetching data:', error);
                }
            } else {
                handleLogout();
            }
        };

        fetchData();
    }, [currentPage]);*/

    const handleSwitchToggle = (id, newState) => {
        // Update the switch state locally
        const updatedStates = permission.map(item =>
            item.permission_id === id ? { ...item, state: newState } : item
        );
        setPermission(updatedStates);

        // Send the updated state to the Django backend
     /*   axios.post('http://localhost:8000/api/switch', { id, state: newState })
            .then(response => {
                console.log("Switch state updated:", response.data);
            })
            .catch(error => {
                console.error("Error updating switch state:", error);
            });*/
    };

    return (
        <Container>
            <Box sx={{ mt: 2, mb: 1 }}>
                <Typography variant="h4" align="center" gutterBottom>
                   Create New User Types
                </Typography>

                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Box>
                            <select
                                id="userType"
                                margin="dense"
                                fullWidth
                                name="userType"
                                value={selectedOption}
                                onChange={handleInputChange}
                                style={{  flex: 1,padding: '5px',fontSize:'18px' }}
                                size="large">
                                <option value="admin">Admin</option>
                                <option value="agent">Process</option>
                                <option value="support">Support</option>


                            </select>

                        </Box>
                    </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <Box>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    type="button"
                                    onClick={fetchPermission}
                                    sx={{ mt: 3 }}>
                                    Search
                                </Button>

                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <Box>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    sx={{ mt: 3 }}>
                                   Update
                                </Button>

                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box>
                                {successMessage && (
                                    <Typography variant="body1" color={successMessage.includes('Error') ? 'error' : 'success'}>
                                        {successMessage}
                                    </Typography>
                                )}
                            </Box>
                        </Grid>

                    </Grid>
                    <TableContainer component={Paper} style={{ marginTop: '10px' }}>
                        <Table size="small">
                            <TableHead sx={{ '& .MuiTableCell-head': { height: '20px' } }}>
                                <TableRow>
                                    <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Permission</TableCell>
                                    <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Actions</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ '& .MuiTableCell-head': { height: '20px' } }}>
                                {permission.length > 0 ? (
                                    permission.map(userPermission => (
                                        <TableRow
                                            key={userPermission.permission_id}

                                            style={{ cursor: 'pointer' }}
                                        >

                                            <TableCell>{userPermission.menus}</TableCell>
                                            <TableCell>  <button type={"button"}
                                                onClick={() => handleSwitchToggle(userPermission.permission_id, !userPermission.state)}
                                                style={{
                                                    padding: '5px 10px',
                                                    backgroundColor: userPermission.state ? 'green' : 'red',
                                                    color: 'white'
                                                }}
                                            >
                                                {userPermission.state ? 'ON' : 'OFF'}
                                            </button></TableCell>

                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={5} align="center">
                                            No tickets available
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>



                </form>
            </Box>

        </Container>
    )

    const styles = StyleSheet.create({
        container: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
        },
    });
}
