import React, { useEffect, useState, useCallback } from 'react';
import {
    Typography,
    Box,
    Paper,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Dialog,
    DialogTitle,
    DialogActions,
    TextField,
    IconButton
} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import axios from 'axios';
import { getValidAccessToken } from '../../utils/tokenUtils';
import {Download, FileOpen} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";

const apiUrl = process.env.REACT_APP_API_URL;


const TaxDraft = ({ ticketId, userType }) => {
    const [folders, setFolders] = useState([]);
    const [files, setFiles] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [newFolderName, setNewFolderName] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [uploading, setUploading] = useState(false);


    const fetchFolders = useCallback(async () => {
        const token = await getValidAccessToken();
        try {
            const response = await axios.get(`${apiUrl}/final-folders/?ticket_id=${ticketId}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (response.data) {
                setFolders(response.data);
            } else {
                setErrorMessage('Unable to fetch folders.');
            }
        } catch (error) {
            setErrorMessage('Error fetching folders.');
            console.error('Error fetching folders:', error);
        }
    }, [ticketId]);

    useEffect(() => {
        fetchFolders();
    }, [fetchFolders]);

  

    const handleFolderClick = async (folderId) => {
        const token = await getValidAccessToken();
        try {
            const response = await axios.get(`${apiUrl}/final-folders/files/?ticket_id=${ticketId}&folder_id=${folderId}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (response.data.success) {
                setFiles(response.data.data);
                setSelectedFolder(folderId);
            } else {
                setErrorMessage('Unable to fetch files.');
            }
        } catch (error) {
            setErrorMessage('Error fetching files.');
            console.error('Error fetching files:', error);
        }
    };

    const handleFileUpload = async (event, folderId) => {
        const token = await getValidAccessToken();
        const file = event.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append('file', file);
        formData.append('ticket_id', ticketId);
        formData.append('folder_id', folderId);
        setUploading(true);
        try {
            const response = await axios.post(`${apiUrl}/final-folders/files/`, formData, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (response.data.success) {
                handleFolderClick(folderId); // Refresh file list
            } else {
                setErrorMessage('Unable to upload file.');
            }
        } catch (error) {
            setErrorMessage('Error uploading file.');
            console.error('Error uploading file:', error);
        }
        finally {
            setUploading(false);
        }
    };

    const handleView = async (fileId,fileName) => {
        const token = await getValidAccessToken();
        try {
            const response = await axios.get(`${apiUrl}/final-file-download/${fileId}/?folder_id=${selectedFolder}&ticket_id=${ticketId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
                responseType: 'blob',  // Make
            });
            const contentDisposition = response.headers.get('Content-Disposition');
           // console.log("Response : "+response.headers)
            const blob = response.data;
            const blobUrl = URL.createObjectURL(blob);

            window.open(blobUrl, '_blank');

        } catch (error) {
            setErrorMessage('Error verifying document.');
            console.error('Error verifying document:', error);
        }
    };
    const handleDownload = async (fileId,fileName) => {
        const token = await getValidAccessToken();
        try {
            const response = await axios.get(`${apiUrl}/final-file-download/${fileId}/?folder_id=${selectedFolder}&ticket_id=${ticketId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
                responseType: 'blob',  // Make
            });
            const contentDisposition = response.headers.get('Content-Disposition');
            console.log("Response : "+response.headers)
            //let fileName = 'defaultFileName.pdf';
            if (contentDisposition && contentDisposition.includes('filename=')) {
                fileName = contentDisposition
                    .split('filename=')[1]
                    .replace(/"/g, ''); // Remove quotes around filename if present
            }

            const blob = response.data;
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href =  url;
            link.setAttribute('download', fileName); // Set file name
            document.body.appendChild(link);
            link.click();  // Trigger the download
            link.parentNode.removeChild(link); // Clean up
            window.URL.revokeObjectURL(url); // Release URL object
        } catch (error) {
            setErrorMessage('Error verifying document.');
            console.error('Error verifying document:', error);
        }
    };

    const handleDelete = async (fileId) => {
        const token = await getValidAccessToken();
        try {

            const  response = await axios.get(`${apiUrl}final-file-delete/${fileId}/?folder_id=${selectedFolder}&ticket_id=${ticketId}`, {
                headers: { 'Authorization': `Bearer ${token}` },

            });
            //  console.log("Response : "+JSON.stringify(response))
            if (response.data.success) {
                await handleFolderClick(selectedFolder);
            } else {
                setErrorMessage('Unable to Delete file details.');
            }
        } catch (error) {
            setErrorMessage('Error verifying document.');
            console.error('Error verifying document:', error);
        }
    };
    const handleCreateFolder = async () => {
        const token = await getValidAccessToken();
        try {
            const response = await axios.post(`${apiUrl}/final-folders/`, {
                ticket_id: ticketId,
                folder_name: newFolderName
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (response.data.success) {
                fetchFolders(); // Refresh folder list
                setOpenDialog(false);
                setNewFolderName('');
            } else {
                setErrorMessage('Unable to create folder.');
            }
        } catch (error) {
            setErrorMessage('Error creating folder.');
            console.error('Error creating folder:', error);
        }
    };

    return (
        <Box>
            {errorMessage && (
                <Typography variant="body2" color="error">{errorMessage}</Typography>
            )}

            {(userType === 'agent' ||userType === 'admin')  && !selectedFolder && (
                <Button variant="contained" onClick={() => setOpenDialog(true)} sx={{ mb: 2 }}>
                    Create New Folder
                </Button>
            )}

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Create a New Folder</DialogTitle>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Folder Name"
                    fullWidth
                    variant="standard"
                    value={newFolderName}
                    onChange={(e) => setNewFolderName(e.target.value)}
                />
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                    <Button onClick={handleCreateFolder} variant="contained">Create</Button>
                </DialogActions>
            </Dialog>

            {!selectedFolder ? (
                <TableContainer component={Paper}>
                    <Table  size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Folder Name</TableCell>
                                <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Creation Date</TableCell>
                                
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {folders.map((folder) => (
                                <TableRow key={folder.folder_id}>
                                    <TableCell>
                                        <Button startIcon={<FolderIcon />} onClick={() => handleFolderClick(folder.folder_id)}>
                                            {folder.folder_name}
                                        </Button>
                                    </TableCell>
                                    <TableCell>{new Date(folder.creation_date).toLocaleString()}</TableCell>
                                   
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Box>
                    <Box sx={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Button variant="outlined" onClick={() => setSelectedFolder(null)} sx={{ mr: 2 }}>
                            Back to Folders
                        </Button>
                        {(userType === 'agent' ||userType === 'admin')  && (
                             <Box sx={{ display: 'flex', justifyContent: 'right', mb: 0 }}>
                             <input
                                     type="file"
                                     onChange={(e) => handleFileUpload(e, selectedFolder)}
                                     style={{ display: 'none' }}
                                     id="upload-file"
                                 />
                             <label htmlFor="upload-file" style={{ cursor: 'pointer' }}>
             <Box
                 sx={{
                     border: '2px dashed #1976d2',
                     borderRadius: '8px',
                     padding: '30px',
                     width: '400px',
                     height: '100px',
                     textAlign: 'center',
                     backgroundColor: '#f9f9f9',
                     color: '#1976d2',
                     '&:hover': {
                         backgroundColor: '#e3f2fd',
                         borderColor: '#1565c0',
                     }
                 }}
             >          {uploading ? 'Uploading...' : 'Upload/Drop files here'}
             </Box>
         </label>
     </Box>
                        )}
                    </Box>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>File ID</TableCell>
                                    <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Filename</TableCell>
                                    <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Uploaded Date</TableCell>
                                    <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold' , fontSize: '0.875rem' }}>Auctions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {files.map((file) => (
                                    <TableRow key={file.file_id}>
                                        <TableCell>{file.file_id}</TableCell>
                                        <TableCell>{file.file_name}</TableCell>
                                        <TableCell>{new Date(file.upload_date).toLocaleString()}</TableCell>
                                        <TableCell sx={{ fontSize: '0.875rem' }}>
                                            <IconButton onClick={() => handleView(file.file_id,file.file_name)} >
                                                <FileOpen color="success" />
                                            </IconButton>
                                            <IconButton onClick={() => handleDownload(file.file_id,file.file_name)} >
                                                <Download color="success" />
                                            </IconButton>
                                            {(userType === 'agent' ||userType === 'admin')  && (
                                                <IconButton onClick={() => handleDelete(file.file_id)} >
                                                    <DeleteIcon color="error" />
                                                </IconButton>
                                            )}

                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}
        </Box>
    );
};

export default TaxDraft;
