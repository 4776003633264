// src/App.js
import React, {useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Signup from './components/Signup';
import Login from './components/Login';
import Home from './components/Home';

import Header from './components/Header';
import '@fontsource/catamaran'; // Defaults to weight 400

import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import CreateTicket from './components/CreateTicket';
import TicketData from './components/TicketData';
import {UserRegisterView} from "./components/admin/UserRegisterView";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

import { LoaderProvider, useLoader } from './context/LoaderContext'; // Correct path to context

import Loader from './utils/Loader'; // Correct path to Loader component
import ReferAndEarn from './components/ReferAndEarn';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';  // Ensure to import the styles



//#ff4700 - orange
//#0e1422 - Blue
//#ffffff - White

function App() {
    const clientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;

    useEffect(() => {
        // Disable right-click
        const disableRightClick = (e) => {
            e.preventDefault();
        };
        document.addEventListener('contextmenu', disableRightClick);

        // Disable DevTools shortcuts
        const disableDevToolsShortcuts = (e) => {
            if (
                e.key === 'F12' ||
                (e.ctrlKey && e.shiftKey && e.key === 'I') ||
                (e.ctrlKey && e.shiftKey && e.key === 'J') ||
                (e.ctrlKey && e.key === 'U')
            ) {
                e.preventDefault();
            }
        };
        document.addEventListener('keydown', disableDevToolsShortcuts);

        return () => {
            document.removeEventListener('contextmenu', disableRightClick);
            document.removeEventListener('keydown', disableDevToolsShortcuts);
        };
    }, []);

    useEffect(() => {
        const detectDevTools = () => {
            let devtoolsOpen = false;
            const threshold = 160;
            const element = new Image();
            Object.defineProperty(element, 'id', {
                get: () => {
                    devtoolsOpen = true;
                    throw new Error('DevTools detected!');
                },
            });
            setInterval(() => {
                devtoolsOpen = false;
               // console.log(element);
                if (devtoolsOpen) {
                    alert('Developer tools are open. Access is restricted.');
                    // Optional: Redirect or take other action
                    window.location.href = '/error';
                }
            }, 1000);
        };

        const interval = setInterval(detectDevTools, 100);
        return () => clearInterval(interval);
    }, []);

  return (
    <LoaderProvider>
<PayPalScriptProvider options={{ "client-id": clientId, currency: "USD" , intent: "capture",       components: "buttons",

 }}>

    <ThemeProvider theme={theme}>
      <Router>
     <Header />
     <AppLoader /> {/* This component will handle showing the loader */}
     <ToastContainer
  position="top-right" // Customize the position of the toast
  autoClose={3000} // Auto close the toast after 3 seconds
  hideProgressBar={false} // Show progress bar
  newestOnTop={false}
  closeOnClick
  rtl={false}
/>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/create-ticket" element={<CreateTicket />} />
          <Route path="/ticket-data" element={<TicketData />} />
          <Route path="/refer-and-earn" element={<ReferAndEarn />} />
          <Route path="/user-register" element={<UserRegisterView />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>

      </Router>
    </ThemeProvider>
    </PayPalScriptProvider>
    </LoaderProvider>

  );
};
const AppLoader = () => {
  const { loading } = useLoader();

  return loading ? <Loader /> : null;  // Show the loader if the loading state is true
};

export default App;
