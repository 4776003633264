import React, { useState } from 'react';
import { 
  CircularProgress, 
  LinearProgress, 
  Button, 
  Backdrop, 
  Dialog, 
  DialogTitle, 
  DialogContent 
} from '@mui/material';

const Dummy = () => {
  // State to manage different loading scenarios
  const [isCircularLoading, setIsCircularLoading] = useState(false);
  const [isLinearLoading, setIsLinearLoading] = useState(false);
  const [isBackdropLoading, setIsBackdropLoading] = useState(false);
  const [isDialogLoading, setIsDialogLoading] = useState(false);

  // Simulated async operation
  const simulateAsyncOperation = async (setLoadingState) => {
    setLoadingState(true);
    try {
      await new Promise(resolve => setTimeout(resolve, 2000));
    } finally {
      setLoadingState(false);
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-xl mb-4">MUI Loading Components Demo</h2>

      {/* Circular Progress */}
      <div className="mb-4">
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => simulateAsyncOperation(setIsCircularLoading)}
        >
          Trigger Circular Loading
        </Button>
        {isCircularLoading && (
          <CircularProgress 
            size={24} 
            className="ml-2" 
            color="primary" 
          />
        )}
      </div>

      {/* Linear Progress */}
      <div className="mb-4">
        <Button 
          variant="contained" 
          color="secondary" 
          onClick={() => simulateAsyncOperation(setIsLinearLoading)}
        >
          Trigger Linear Loading
        </Button>
        {isLinearLoading && <LinearProgress className="mt-2" />}
      </div>

      {/* Backdrop Loading */}
      <div className="mb-4">
        <Button 
          variant="contained" 
          color="warning" 
          onClick={() => simulateAsyncOperation(setIsBackdropLoading)}
        >
          Trigger Backdrop Loading
        </Button>
        <Backdrop 
          open={isBackdropLoading} 
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      {/* Dialog Loading */}
      <div>
        <Button 
          variant="contained" 
          color="info" 
          onClick={() => simulateAsyncOperation(setIsDialogLoading)}
        >
          Trigger Dialog Loading
        </Button>
        <Dialog open={isDialogLoading}>
          <DialogTitle>Loading</DialogTitle>
          <DialogContent>
            <div className="flex justify-center items-center p-4">
              <CircularProgress />
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default Dummy;