import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import axios from 'axios';
import { getValidAccessToken } from '../../utils/tokenUtils';

import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { CheckCircle } from '@mui/icons-material';


const apiUrl = process.env.REACT_APP_API_URL;

const initializeInvoice = () => ({
    date: new Date().toLocaleDateString(),
    items: [{ description: '', rate: 0, quantity: 1 }], // Default rate 0, quantity 1
    notes: '',
    discount: 0
});

const Invoice = ({ userType, ticketData, setTicketData }) => {

    const isInvoiceEmpty = !ticketData.invoice || Object.keys(ticketData.invoice).length === 0 || ticketData.invoice === null; // Check if invoice is null or empty
    const [invoiceData, setInvoiceData] = useState(isInvoiceEmpty ? initializeInvoice() : ticketData.invoice);
    const [isCreating, setIsCreating] = useState(isInvoiceEmpty); // Set to true if invoice is null or empty
    const [discount, setDiscount] = useState(ticketData.invoice?.discount || 0);



    const handleInputChange = (field, value) => {
        setInvoiceData({ ...invoiceData, [field]: value });
    };

    const handleItemChange = (index, field, value) => {
        const newItems = [...invoiceData.items];
        if (field === 'rate' && value < 0) value = 0; // Ensure rate is not negative
        if (field === 'quantity' && value < 1) value = 1; // Ensure quantity is at least 1
        newItems[index][field] = value;
        setInvoiceData({ ...invoiceData, items: newItems });
    };

    const addItem = () => {
        setInvoiceData({ ...invoiceData, items: [...invoiceData.items, { description: '', rate: 0, quantity: 1 }] });
    };

    const calculateSubtotal = () => {
        if (!invoiceData || !invoiceData.items) return 0;
        return invoiceData.items.reduce((acc, item) => acc + item.rate * item.quantity, 0);
    };

    const calculateTotal = (subtotal) => {
        return subtotal - discount;
    };



    const handleCreateInvoice = async () => {
        const token = await getValidAccessToken();
        try {
            const response = await axios.post(`${apiUrl}/ticket/create-invoice/`, {
                ticket_id: ticketData.ticket_id
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (response.data.success) {
                setTicketData(response.data.data);
                setInvoiceData({ ...initializeInvoice(), discount: 0 });
                setIsCreating(false);
            } else {
                console.error('Error creating invoice');
            }
        } catch (error) {
            console.error('Error creating invoice:', error);
        }
    };

    const handleSubmit = async () => {
        const token = await getValidAccessToken();
        try {
            const response = await axios.post(`${apiUrl}/ticket/update-invoice/`, {
                ticket_id: ticketData.ticket_id,
                invoice: { ...invoiceData, discount }
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (response.data.success) {
                setTicketData(response.data.data);
            } else {
                console.error('Error updating invoice');
            }
        } catch (error) {
            console.error('Error updating invoice:', error);
        }
    };

    const subtotal = calculateSubtotal();
    const total = calculateTotal(subtotal);

    const createOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [{
                amount: {
                    value: total.toFixed(2), // Use the calculated total amount
                },
                description: 'Payment', // You can also pass a description for better clarity
                custom_id: ticketData.ticket_id, // Custom field to pass ticket_id
                invoice_id: ticketData.invoice_id, // Pass invoice_id directly
            }],
        });
    };

    const onApprove = (data, actions) => {
        actions.order.capture().then(details => {
            console.log('Transaction Details:', details);
    
            // Get ticket and invoice data
            const invoiceId = details.purchase_units[0].invoice_id;
            const ticketId = details.purchase_units[0].custom_id;
    
            console.log('Ticket details', ticketId, invoiceId);
            
    
            // Handle post-transaction logic here
            getValidAccessToken().then(token => {
                console.log('Token details', token);
    
                axios.post(
                    `${apiUrl}/ticket/update-payment-details/`,
                    {
                        ticket_id: ticketId,
                        invoice_id: invoiceId,
                        transaction_id: details.id,
                        transactional_details: details, // If needed, pass only relevant details
                    },
                    {
                        headers: { 'Authorization': `Bearer ${token}` },
                    }
                )
                .then(response => {
                    if (response.data.success) {
                        setTicketData(response.data.data);
                        alert('Payment was successfully processed.');
                    } else {
                        console.error('Error updating invoice:', response.data.error);
                        alert('There was an error updating the payment. Please try again.');
                    }
                })
                .catch(error => {
                    console.error('Error updating invoice:', error);
                    alert('An error occurred while processing your payment. Please try again.');
                });
            })
            .catch(error => {
                console.error('Error fetching token:', error);
                alert('An error occurred while fetching the access token. Please try again.');
            });
        })
        .catch(error => {
            console.error('Error capturing transaction:', error);
            alert('An error occurred during the transaction. Please try again.');
        });
    };
    

    return (

        <Box sx={{ p: 3 }}>
            {(isInvoiceEmpty && !isCreating) ? (
                userType === 'agent' ? (
                    <>
                        <Typography>No invoice found</Typography>
                        <Button variant="contained" onClick={handleCreateInvoice}>Create Invoice</Button>
                    </>
                ) : (
                    <Typography>Invoice Not Created</Typography>
                )
            ) : (
                <>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 0 }}>
                        {/* Logo Section */}
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <img src={`${process.env.PUBLIC_URL}/log3.png`} alt="Logo" style={{ width: 300 }} />
                        </Box>

                        {/* Invoice Details Section */}
                        <Box sx={{ textAlign: 'right', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                            <Typography variant="h6">Invoice</Typography>
                            <Typography>Invoice Number: {ticketData.invoice_id || 'N/A'}</Typography>
                            <Typography>Date: {invoiceData.date}</Typography>
                        </Box>
                    </Box>

                    {/* From and To Fields Below the Logo */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mt: 0 }}>
                        <Typography>From: Save Tax LLC</Typography>
                        <Typography>To: Dummy Address</Typography>
                    </Box>




                    <TableContainer component={Paper} sx={{ mt: 2 }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold', fontSize: '0.875rem', width: '70%' }}>Description</TableCell>
                                    <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold', fontSize: '0.875rem', width: '10%' }}>Rate</TableCell>
                                    <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold', fontSize: '0.875rem', width: '10%' }}>Quantity</TableCell>
                                    <TableCell sx={{ backgroundColor: 'secondary.main', color: 'white', fontWeight: 'bold', fontSize: '0.875rem', width: '10%' }}>Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {invoiceData.items.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {userType === 'agent' ? (
                                                <TextField
                                                    value={item.description}
                                                    onChange={(e) => handleItemChange(index, 'description', e.target.value)}
                                                    fullWidth
                                                    size="small"
                                                    disabled={ticketData.invoice_status === 'Payment Received'} // Disable if invoice_status is 'Payment Received'

                                                />
                                            ) : (
                                                <Typography>{item.description}</Typography>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {userType === 'agent' ? (
                                                <TextField
                                                    type="number"
                                                    value={item.rate}
                                                    onChange={(e) => handleItemChange(index, 'rate', parseFloat(e.target.value))}
                                                    fullWidth
                                                    size="small"
                                                    inputProps={{ min: 0 }}
                                                    disabled={ticketData.invoice_status === 'Payment Received'} // Disable if invoice_status is 'Payment Received'

                                                />
                                            ) : (
                                                <Typography>${item.rate.toFixed(2)}</Typography>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {userType === 'agent' ? (
                                                <TextField
                                                    type="number"
                                                    value={item.quantity}
                                                    onChange={(e) => handleItemChange(index, 'quantity', parseInt(e.target.value))}
                                                    fullWidth
                                                    size="small"
                                                    inputProps={{ min: 1 }}
                                                    disabled={ticketData.invoice_status === 'Payment Received'} // Disable if invoice_status is 'Payment Received'

                                                />
                                            ) : (
                                                <Typography>{item.quantity}</Typography>
                                            )}
                                        </TableCell>
                                        <TableCell>${(item.rate * item.quantity).toFixed(2)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {userType === 'agent' && (
                        <Button onClick={addItem} sx={{ mt: 2 }}>Add Item</Button>
                    )}

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                        <Box sx={{ flexGrow: 1, mr: 2 }}>
                            {userType === 'agent' ? (
                                <TextField
                                    label="Notes"
                                    multiline
                                    rows={4}
                                    value={invoiceData.notes}
                                    onChange={(e) => handleInputChange('notes', e.target.value)}
                                    fullWidth
                                    disabled={ticketData.invoice_status === 'Payment Received'} // Disable if invoice_status is 'Payment Received'

                                />
                            ) : (
                                <Typography>Notes: {invoiceData.notes}</Typography>
                            )}
                        </Box>
                        <Box >
                            <Typography>Subtotal: ${subtotal.toFixed(2)}</Typography>
                            {userType === 'agent' && (
                                <TextField
                                    label="Discount"
                                    type="number"
                                    value={discount}
                                    onChange={(e) => setDiscount(parseFloat(e.target.value))}
                                    sx={{ mb: 2 }}
                                    fullWidth
                                    size="small"
                                    disabled={ticketData.invoice_status === 'Payment Received'} // Disable if invoice_status is 'Payment Received'
                                />
                            )}
                            {discount > 0 && (
                                <Typography>Discount: ${discount.toFixed(2)}</Typography>
                            )}
                            <Typography>Total: ${total.toFixed(2)}</Typography>
                        </Box>
                    </Box>

 
                    {userType === 'agent' && ticketData.invoice_status !== 'Payment Received' && (
    <Button onClick={handleSubmit} variant="contained" sx={{ mt: 3 }}>
        Submit
    </Button>
)}


                    {userType === 'user' && ticketData.invoice_status === 'Invoice Generated' && (
                        <Box>
                            <PayPalButtons
                                style={{
                                    layout: "vertical",  // You can keep this if you prefer a vertical layout
                                    height: 40,          // Set a desired height
                                    width: 800,          // Set a desired width (you may adjust this based on the available space)
                                    padding: 55,          // Optional: reduces the button padding to make it smaller
                                    shape: "rect",       // Optional: makes the button rectangular, rather than a pill shape
                                }}
                                createOrder={createOrder}
                                onApprove={onApprove}
                                fundingSource="paypal" // Default to PayPal and Credit/Debit option

                            />
                        </Box>
                    )}

{ userType === 'user' && ticketData.invoice_status === 'Payment Received' && (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
    <Paper
      elevation={3}
      sx={{
        padding: '20px',
        borderRadius: '8px',
        backgroundColor: 'success.main',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0px 4px 12px rgba(0, 128, 0, 0.3)',
        width: '80%',  // Adjust width as needed
        maxWidth: '500px',
      }}
    >
      <CheckCircle sx={{ fontSize: 32, marginRight: 2 }} />
      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
        Thanks for your payment. Payment is successfully received!
      </Typography>
    </Paper>
  </Box>
)}


{ userType === 'agent' && ticketData.invoice_status === 'Payment Received' && (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
    <Paper
      elevation={3}
      sx={{
        padding: '20px',
        borderRadius: '8px',
        backgroundColor: 'success.main',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0px 4px 12px rgba(0, 128, 0, 0.3)',
        width: '80%',  // Adjust width as needed
        maxWidth: '500px',
      }}
    >
      <CheckCircle sx={{ fontSize: 32, marginRight: 2 }} />
      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
        Payment is received!
      </Typography>
    </Paper>
  </Box>
)}

                </>
            )}
        </Box>
    );
};

export default Invoice;
