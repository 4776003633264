import {useEffect, useState} from "react";
import {getValidAccessToken} from "../../../utils/tokenUtils";
import jsPDF from 'jspdf';
import autoTable from "jspdf-autotable";
import axios from "axios";
import BusinessFormationForm from "../OrganizerTabs/BusinessFormationForm";
const apiUrl = process.env.REACT_APP_API_URL;

const PdfView = ({ticketData,mailingAddressDetailsValues,personalDetailsValues,spouseDetailsValues}) => {
    const [basicDetailsForm, setBasicDetailsForm] = useState({});
    const [dependentsForm, setDependentsForm] = useState({});
    const [stateDetailsForm, setStateDetailsForm] = useState({});
    const [incomesForm, setIncomesForm] = useState({});
    const [rentalForm, setRentalForm] = useState({});
    const [expensesForm, setExpensesForm] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchBasicDetailsForm = async () => {
            try {
                const token = await getValidAccessToken();
                const response = await axios.post(
                    `${apiUrl}/tax-organizer-details/`,
                    {
                        tax_organizer_id: ticketData.tax_organizer_id,
                        column_names: 'basic_details_form'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                const details = response.data.data.basic_details_form;
                if (details) {
                    const parsedDetails = JSON.parse(details);
                    setBasicDetailsForm(parsedDetails);

                }
            } catch (error) {
                console.error('Error fetching business formation details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBasicDetailsForm();
    }, [ticketData.tax_organizer_id]);

    useEffect(() => {
        const fetchDependentView = async () => {
            try {
                const token = await getValidAccessToken();
                const response = await axios.post(
                    `${apiUrl}/tax-organizer-details/`,
                    {
                        tax_organizer_id: ticketData.tax_organizer_id,
                        column_names: 'dependents_details'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                const details = response.data.data.dependents_details;
                if (details) {
                    const parsedDetails = JSON.parse(details);
                    setDependentsForm(parsedDetails);
                    //setVehicleInfo(parsedDetails.vehicleInfo || []);
                }
            } catch (error) {
                console.error('Error fetching business formation details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchDependentView();
    }, [ticketData.tax_organizer_id]);



    useEffect(() => {
        const fetchStateView = async () => {
            try {
                const token = await getValidAccessToken();
                const response = await axios.post(
                    `${apiUrl}/tax-organizer-details/`,
                    {
                        tax_organizer_id: ticketData.tax_organizer_id,
                        column_names: 'state_details'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                const details = response.data.data.state_details;
                if (details) {
                    const parsedDetails = JSON.parse(details);
                    setStateDetailsForm(parsedDetails);
                    // setTaxPayerResidency(parsedDetails.taxPayerResidency || []);
                    // setSpouseResidency(parsedDetails.spouseResidency || []);
                }
            } catch (error) {
                console.error('Error fetching business formation details:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchStateView();
    }, [ticketData.tax_organizer_id]);

    useEffect(() => {
        const fetchIncomeView = async () => {
            try {
                const token = await getValidAccessToken();
                const response = await axios.post(
                    `${apiUrl}/tax-organizer-details/`,
                    {
                        tax_organizer_id: ticketData.tax_organizer_id,
                        column_names: 'income_details'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                const details = response.data.data.income_details;
                if (details) {
                    const parsedDetails = JSON.parse(details);
                    setIncomesForm(parsedDetails);
                    // setIncomeValues(parsedDetails.incomeValues || []);
                    // if (Array.isArray(parsedDetails.foreignIncome) && parsedDetails.foreignIncome.length > 0) {
                    //  setForeignIncome(parsedDetails.foreignIncome);}

                }
            } catch (error) {
                console.error('Error fetching business formation details:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchIncomeView ();
    }, [ticketData.tax_organizer_id]);

    useEffect(() => {
        const fetchRentalView = async () => {
            try {
                const token = await getValidAccessToken();
                const response = await axios.post(
                    `${apiUrl}/tax-organizer-details/`,
                    {
                        tax_organizer_id: ticketData.tax_organizer_id,
                        column_names: 'rental_details'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                const details = response.data.data.rental_details;
                if (details) {
                    const parsedDetails = JSON.parse(details);
                    setRentalForm(parsedDetails);
                    //setRentalInfo(parsedDetails.rentalInfo || []);
                }
            } catch (error) {
                console.error('Error fetching business formation details:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchRentalView();
    }, [ticketData.tax_organizer_id]);

    useEffect(() => {
        const fetchExpencesView = async () => {
            try {
                const token = await getValidAccessToken();
                const response = await axios.post(
                    `${apiUrl}/tax-organizer-details/`,
                    {
                        tax_organizer_id: ticketData.tax_organizer_id,
                        column_names: 'expenses_details'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                const details = response.data.data.expenses_details;
                if (details) {
                    const parsedDetails = JSON.parse(details);
                    setExpensesForm(parsedDetails);
                    //setDayCareValues(parsedDetails.dayCareValues || []);
                    // setVehicleValues(parsedDetails.vehiclaValues || []);
                }
            } catch (error) {
                console.error('Error fetching business formation details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchExpencesView();
    }, [ticketData.tax_organizer_id]);

    const handleDownloadPdf2 = () => {
        const doc = new jsPDF();
        let y  = 10;
        let headValue ='Save Tax LLC';
        let xOffset = (doc.internal.pageSize.width / 2) - (doc.getTextWidth(headValue) / 2);
        doc.text(headValue, xOffset, y);

        var img = new Image();
        img.src = "favicon.png";

        doc.addImage(img, 10, 2, 25, 25);

        const pageWidth = doc.internal.pageSize.width - 30;
        const halfWidth = pageWidth * 0.5;

        let rowdatabase = [];




        let personals = JSON.parse(JSON.stringify(personalDetailsValues))
        let spouse = JSON.parse(JSON.stringify(spouseDetailsValues))
        rowdatabase.push(['First Name',personals.first_name,spouse.first_name]);
        rowdatabase.push(['Middle Name',personals.middle_name,spouse.middle_name]);
        rowdatabase.push(['Last Name',personals.last_name,spouse.last_name]);
        rowdatabase.push(['Phone No',personals.phone_no,spouse.phone_no]);
        rowdatabase.push(['Email',personals.email,spouse.email]);
        rowdatabase.push(['SSN',personals.ssn,spouse.ssn]);
        rowdatabase.push(['Date Of Birth',personals.date_of_birth,spouse.date_of_birth]);
        rowdatabase.push(['Occupation',personals.occupation,spouse.occupation]);
        rowdatabase.push(['Employer',personals.employer,spouse.employer]);
        rowdatabase.push(['Designation',personals.designation,spouse.designation]);
        rowdatabase.push(['First Port of entry into UnitedStates',personals.first_port_of_entry_into_united_states,spouse.first_port_of_entry_into_united_states]);
        rowdatabase.push(['Visa Type',personals.current_visa_type,spouse.current_visa_type]);
        rowdatabase.push(['Was there a change in your Visa',personals.was_there_any_change_in_your_visa_during_2023,spouse.was_there_any_change_in_your_visa_during_2023]);
        rowdatabase.push(['Have you resided atleast 6_months in US during 2023',personals.did_you_reside_at_least_6_months_in_US_during_2023,spouse.did_you_reside_at_least_6_months_in_US_during_2023]);
        rowdatabase.push(['Will you reside atleast 6 months in US in 2024',personals.will_you_reside_at_least_6_months_in_US_in_2024,spouse.will_you_reside_at_least_6_months_in_US_in_2024]);
        rowdatabase.push(['Yearly House Hold Income',personals.yearly_household_income,spouse.yearly_household_income]);
        rowdatabase.push(['Marital Status',personals.marital_status,spouse.marital_status]);
        rowdatabase.push(['Date of Marriage',personals.date_of_mariage,spouse.date_of_mariage]);

        autoTable(doc, {
            startY: y + 20, // Starting position for the first table
            head: [
                [{content:'Basic Details',colSpan:3}],
                ['','Tax Payer', 'Spouse']
            ],
            body:rowdatabase,
            headStyles: {halign: "center",fontStyle:"bold",textColor: 0, fillColor: [255,255,255], lineWidth: 0.2,lineColor: [0, 0, 0] },
            columnStyles: {
                0:
                    {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 11,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],
                        cellWidth:"50%"
                    },
                1: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:"25%"
                },
                2: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:"25%"
                },
            }
        });
        y = doc.lastAutoTable.finalY + 10;

        let addressdatabase = [];

        console.log("Mailing  "+JSON.stringify(mailingAddressDetailsValues));
        addressdatabase.push(['Apartment No',mailingAddressDetailsValues.apartment_no]);
        addressdatabase.push(['Address',mailingAddressDetailsValues.address_line1]);
        addressdatabase.push(['Address',mailingAddressDetailsValues.address_line2]);
        addressdatabase.push(['City',mailingAddressDetailsValues.city]);
        addressdatabase.push(['State',mailingAddressDetailsValues.state]);
        addressdatabase.push(['Zipcode',mailingAddressDetailsValues.zip_code]);
        addressdatabase.push(['Country',mailingAddressDetailsValues.country]);
        //addressdatabase.push(['comments',mailingAddressDetailsValues.apartment_no]);

        autoTable(doc, {
            startY: y + 10, // Starting position for the first table
            head: [
                [{content:'Address',colSpan:2}],
                ['', '']
            ],
            body: addressdatabase,
            headStyles: {halign: "center",fontStyle:"bold",textColor: 0, fillColor: [255,255,255], lineWidth: 0.2,lineColor: [0, 0, 0] },
            columnStyles: {
                0:
                    {
                        halign: "left",
                        valign: 'middle',
                        fontStyle: "bold",
                        fontSize: 11,
                        textColor: "#000",
                        cellPadding: 1,
                        lineWidth: 0.2,
                        lineColor: [0, 0, 0],
                        cellWidth:"50%"
                    },
                1: {
                    halign: "center",
                    valign: 'middle',
                    fontStyle: "bold",
                    fontSize: 9,
                    textColor: "#000",
                    cellPadding: 1,
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                    cellWidth:"25%"
                },
            }
        });
        doc.addPage();
        //doc.save('partner_information_details.pdf');
    };
}
export default PdfView;
